@import 'styles/global.scss';

.carrier-order-services {
  .service-list {
    .item {
      flex-direction: column;
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 8px;
      }

      .info-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .info .comment {
        padding-top: 8px;
      }

      .location {
        display: flex;
        padding: 10px 0 4px 0;

        .location-link {
          width: auto;
        }

        .distance {
          margin-left: 15px;
          color: $grey;
          white-space: nowrap;
        }
      }

      .info {
        display: flex;
        align-items: center;

        .icon {
          flex-shrink: 0;
        }

        .title {
          margin-left: 4px;
        }
      }

      .value {
        flex-grow: 0;
      }
    }
  }

  .service-details {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    flex-grow: 1;

    .service-params {
      display: flex;
    }

    .item {
      flex-direction: column;
      margin-top: 0;
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }

      .label {
        padding-bottom: 5px;
      }

      .days-count {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: $dark;
        padding-top: 5px;
        font-weight: 500;
      }
    }
  }
}

@import '@/assets/scss/global.scss';

.orders-list {
    & > div {
        padding-bottom: 50px;
    }

    .refresh {
        margin-left: 0;

        button {
            background: white;
            height: 40px;
            padding: 8px;
            border-radius: 3px;
            border: solid 1px #e5e9f1;

            &:hover {
                border-color: #eff0f1;
            }
        }
    }

    .page-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background: none;
        > div {
            height: auto;
        }
        .form-filters {
            margin-bottom: 15px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .orders-list {
        .refresh {
            margin-right: 0;
        }
        & > div {
            padding-bottom: 15px;

            .page-header {
                display: flex;
                justify-content: space-between;

                .icon-filter {
                    display: flex;
                    align-items: center;
                    padding: 30px 15px 15px 15px;
                    color: $dark-blue;
                    font-size: 20px;
                    transition: none;

                    &.opened {
                        color: $blue;
                    }
                }
            }
        }

        .page-header-wrapper {
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            .form-filters {
                margin-bottom: 0;

                &.mobile-hidden {
                    display: none;
                }
            }
        }
    }
}

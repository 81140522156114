.modal-one-field {
    max-width: 420px;
    .modal-content {
        .one-field-form {
            > .modal-header {
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                /* identical to box height */

                text-align: center;
                padding-bottom: 12px;

                /* Black/black-85-text-primary */

                color: rgba(9, 21, 38, 0.85);

            }

        }

        .message {
            margin: 0 auto;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;

            color: #000000;

            opacity: 0.6;
            max-width: 330px;
            padding: 0px 0 20px 0;
        }

    }
    .input-text {
        max-width: 65%;
        margin: 0 auto;
    }
    .btns {
        max-width: 65%;
        margin: 24px auto 12px auto;
        display: flex;
        justify-content: center;

        button {
            width: 100%;
        }
    }
    .modal-one-field_footer {
        margin: 24px auto 12px auto;
        width: 100%;
        text-align: center;

    }
}

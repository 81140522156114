@import '@/assets/scss/global.scss';

.modal-rating {
    .rating {
        padding: 10px 0;

        .groups {
            display: flex;
            flex-wrap: wrap;

            .rating-item {
                width: 50%;
                flex-shrink: 0;
                margin-bottom: 20px;
            }
        }
    }

    .btns {
        padding-top: 10px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .modal-rating {
        .rating {
            .groups {
                .rating-item {
                    margin-bottom: 10px;
                }
            }
        }

        .btns {
            padding-top: 0;
        }
    }
}

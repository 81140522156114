@import 'styles/global.scss';

.modal-card {
    .card {
        position: relative;
        height: 230px;
        padding: 25px 20px;

        .back {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            width: 350px;
            height: 100%;
            background-image: linear-gradient(164deg, #e8ebef, #cdd1da);
            border-radius: 20px;
        }

        .main {
            position: absolute;
            top: 0;
            left: 0;
            width: 350px;
            height: 100%;
            background-image: linear-gradient(169deg, #edeff4, #e1e4eb);
            border-radius: 20px;
        }

        .fields {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
        }

        .icon {
            position: relative;
            display: block;
            width: 60px;
            height: 40px;
        }

        .field {
            margin: 0;
            flex-shrink: 0;

            &.number {
                width: 310px;
                margin-bottom: 15px;
                margin-right: 40px;
            }

            &.name {
                width: 205px;
            }

            &.date {
                width: 90px;
                margin-left: 15px;
            }

            &.cvv {
                width: 80px;
            }
        }
    }

    .btns {
        padding-top: 40px;
        display: flex;

        button {
            min-width: 140px;
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: $media-limit-mobile) {
    .modal-card {
        .card {
            height: auto;

            .back {
                display: none;
            }

            .main {
                width: 100%;
            }

            .fields {
                margin-top: 10px;
            }

            .field {
                &.number,
                &.cvv,
                &.date,
                &.name {
                    width: 100%;
                    margin: 0 0 10px 0;
                }
            }
        }

        .btns {
            padding-top: 30px;

            button {
                width: 100%;
                margin: 0;

                &.plain {
                    display: none;
                }
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.location-link {
    width: 100%;

    .link-wrap {
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid transparent;
        cursor: pointer;

        &:not(.empty):hover > *:not(.tooltip) {
            border-bottom: 1px solid $blue;
            color: $blue;

            &.zipcode {
                color: $blue;
            }

            &.icon-pin {
                border-bottom: 1px solid transparent;
            }
        }

        &:hover {
            .tooltip {
                opacity: 1;
                visibility: visible;
            }
        }

        & > * {
            border-bottom: 1px solid transparent;
            transition: all 0.2s linear;
        }

        &.empty {
            cursor: default;
        }

        &.multyline {
            align-items: flex-start;

            &:hover > * {
                border-bottom: 1px solid transparent !important;
            }

            .icon-pin {
                line-height: 19px;
            }

            .address {
                white-space: normal;
            }
        }
    }

    .address {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .zipcode {
        color: #979ea6;
        padding-left: 5px;
        white-space: nowrap;
    }

    .icon-pin {
        margin-right: 10px;
        font-size: 12px;

        &:before {
            transition: none;
        }
    }

    .tooltip {
        left: 20px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .location-link {
        width: auto;

        .link-wrap {
            display: block;
            border-bottom: none;

            &:not(.empty):hover > *:not(.tooltip) {
                border-bottom: 1px solid $blue;
                color: $blue;

                &.zipcode {
                    color: $blue;
                }

                &.icon-pin {
                    border-bottom: 1px solid transparent;
                }
            }

            &:hover {
                .tooltip {
                    display: none;
                }
            }

            & > * {
                border-bottom: none;
                display: inline;
            }
        }

        .address {
            white-space: normal;
        }
    }
}

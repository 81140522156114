@import '@/assets/scss/global.scss';

.dropdown-documents {
    &__download {
        font-size: 19px;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;

        svg {
            path {
                transition: all 0.2s linear;
                color: $black;
            }
        }

        &:hover,
        &:focus,
        &:active {
            .dropdown-documents__label,
            .icon-arrow {
                color: $blue !important;
            }

            svg {
                path {
                    color: $blue;
                }
            }
        }
    }

    &__label {
        font-family: UniNeue;
        font-size: 15px !important;
        font-weight: bold;
        transition: all 0.2s linear;
        color: $black;
    }

    .icon-arrow {
        font-size: 6px;
        margin-left: 8px;

        &.reversed {
            transform: scale(1, -1);
        }
    }

    .list {
        padding: 8px 0;
        background: #ffffff;
        box-shadow: 4px 6px 24px rgba(65, 98, 131, 0.25);
        border-radius: 8px;
        width: 320px;
    }

    &__link {
        border-radius: 0 !important;
        border: none !important;
        background-color: $white !important;

        &:hover {
            background-color: #f3f4fa !important;
        }
    }
}

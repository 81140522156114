@import '@/assets/scss/global.scss';

.disclaimer.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0.98;
    background-color: #fff;
    z-index: 100;
    width: 100%;
    padding: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #979ea6;
    border-top: 1px solid $border-color;
    overflow: auto;

    & > div {
        position: relative;
        padding-right: 20px;
    }

    .html {
        padding: 8px 16px;
    }

    .icon-close {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        font-size: 11px;

        &:hover {
            color: #aeb6bf;
        }

        &:active {
            color: #757e88;
        }
    }

    a {
        font-size: 13px;
        line-height: 1.31;
        color: #51585f;
        border-bottom: 1px solid #51585f;
        transition: color 0.2s linear;

        &:hover {
            color: #000;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .disclaimer {
        padding: 15px 20px;
    }
}

@import 'styles/global.scss';

.working-days-wrapper {
    width: 100%;
    .label {
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.1px;
        color: #000;
        text-transform: uppercase;
        padding-bottom: 7px;
        min-height: 21px;
    }
}

.working-days {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0 20px;

    & > div {
        background: $light-silver;
        padding: 12px 4px;
        border-radius: 8px;
        margin-right: 4px;
        cursor: pointer;
        font-size: 11px;
        font-weight: 500;
        width: 40px;
        text-align: center;
        border: 1px solid #E5E9F1;


        &.active {
            background: $blue;
            color: #fff;
        }
    }
}
.work-time-from {
    position: relative;

    .dash {
        position: absolute;
        left: 100%;
        bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 1px;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        color: $dark;
    }
}


.warehouse-settings-warehouse {
    .fieldset {
        padding-top: 27px;
        padding-bottom: 30px;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .form-wrap {
        display: flex;

        .left {
            display: flex;
            width: calc((100% - 40px) / 2);
            flex-shrink: 0;
            flex-wrap: wrap;

            &:first-child {
                margin-right: 40px;
            }

            .field {
                width: auto;
                margin-right: 40px;
                flex-shrink: 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }

        .form-wrap-item {
            margin-right: 16px;
        }
    }

    .group-service-section {
        margin-bottom: 24px;
        width: 100%;
        padding: 16px;
        border: 1px solid $border-color;
    }

    .services-section {
        margin-bottom: 24px;

        .form-wrap-item {
            margin-bottom: 12px;
        }
    }

    .wh-service-comment {
        width: 100%;
        margin-bottom: 15px;
        margin-top: 16px;
    }

    .license {
        margin-top: 10px;
    }

    .area {
        margin-right: 25px;
        margin-top: 10px;
    }

    .auto-booking {
        margin-top: 14px;

        .label {
            font-weight: 600;
        }
    }

    .addresses {
        .field {
            display: flex;

            .input-location {
                flex-shrink: 0;
                width: calc((2 * (100% - 80px) / 3) + 40px);
                margin-right: 40px;
            }

            .checkbox {
                padding-top: 30px;
            }
        }
    }

    .services {
        display: flex;
        flex-wrap: wrap;
        width: 80%;

        .field {
            width: calc((100% - 80px) / 3);
            margin-right: 40px;
            flex-shrink: 0;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .checkbox {
                margin-bottom: 15px;
            }
        }
    }

    .collapse-box {
        cursor: pointer;
        width: 100%;
        border-radius: 2px;
        background: #fafafa;
        font-weight: 500;
        border: 1px solid $border-color;
        margin-bottom: 8px;

        .collapse-panel {
            display: flex;
            justify-content: space-between;
            padding: 4px 8px;
        }

        .collapse-content {
            padding: 8px;

            .collapse-content {
                background-color: $white;
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .warehouse-settings-warehouse {
        .form-wrap {
            display: flex;
            flex-direction: column;

            .left {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                &:first-child {
                    margin-right: 40px;
                }

                .field {
                    width: 100%;
                    margin-right: 0;

                    &.auto-booking {
                        margin-top: 0;
                    }

                    &.work-time-from {
                        width: calc(50% - 10px);

                        .dash {
                            width: 20px;
                            padding: 0 10px;
                            bottom: 34px;
                        }
                    }

                    &.work-time-to {
                        margin-bottom: 20px;
                        width: calc(50% - 10px);
                        margin-left: 20px;
                    }

                    &.capacity {
                        order: 3;
                    }

                    &.refrigerator-capacity {
                        order: 4;
                    }

                    &.gates {
                        order: 5;
                    }

                    &.license {
                        order: 6;
                    }

                    &.capacity,
                    &.refrigerator-capacity,
                    &.gates,
                    &.license {
                        width: calc(50% - 10px);
                    }

                    &.capacity,
                    &.gates {
                        margin-right: 20px;
                    }
                }
            }

            .right {
                flex-grow: 1;
            }
        }

        .addresses {
            .field {
                display: flex;

                &.legal-address,
                &.billing-address {
                    display: block;
                }

                .input-location {
                    flex-shrink: 0;
                    width: 100%;
                    margin-right: 0;
                }

                .checkbox {
                    padding: 0;
                    margin-bottom: 30px;
                }
            }
        }

        .services {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .field {
                width: 100%;
                margin-right: 0;

                .checkbox {
                    margin-bottom: 15px;
                }
            }
        }
    }
}


.sign-up_warehouse-hours {
  .work-time-from-to {
    display: flex;

    .work-time-from {
      width: 48%;
      margin-right: 40px;
    }
    .work-time-to {
      width: calc(52% - 40px);

    }
  }
}

.sign-up_warehouse-address {
  .addresses {
    .field {
      position: relative;
      margin-bottom: 12px;

      .checkbox {
        position: absolute;
        right: 0;
        top: -4px;
        align-items: center;
        margin-top: 0;
      }
    }
  }
}

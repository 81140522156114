@import '@/assets/scss/global.scss';

.collapse-box {
    cursor: pointer;
    width: 100%;
    border-radius: 2px;
    background: #fafafa;
    font-weight: 500;
    border: 1px solid $border-color;
    margin-bottom: 8px;

    button {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .collapse-panel {
        display: flex;
        justify-content: space-between;
        padding: 4px 8px;
    }

    .collapse-content {
        padding: 8px;
    }

    .collapse-state {
        color: #519cec;
    }
}

@import 'styles/global.scss';

.carrier-order-item {
    &.wrapper {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        padding-top: 32px;
        padding-bottom: 63px;
        > .loader {
            margin-top: 31px;
        }
    }

    &__wrapper.wrap {
        max-width: 620px;
        border-radius: 8px !important;

        &:not(:first-child) {
            margin-top: 36px;
        }

        &.carrier-order-item__wrapper--prices {
            margin-top: 6px;
        }
    }

    .section {
        padding-top: 40px;

        &:first-child {
            padding-top: 0;
        }
    }

    &__header {
        padding: 0 !important;

        .btns {
            display: flex;

            .actions {
                display: flex;

                button {
                    margin-left: 20px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .dropdown-documents {
                margin-right: 20px;
            }
        }
    }

    &__header-wrapper {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    &__status {
        margin-top: 12px;
        width: auto;
    }

    &__status-tip {
        margin-top: 12px;
        display: flex;
        align-items: center;
        color: $black;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        .hint {
            display: inline-flex;
            margin-left: 5px;
        }
    }

    .group {
        padding-top: 24px;

        &:first-child {
            padding-top: 0;
        }

        > .item {
            display: flex;
            margin-top: 24px;

            &:first-child {
                margin-top: 0;
            }

            .label {
                color: rgba(0, 0, 0, 0.6);
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                width: 160px;
                flex-shrink: 0;
            }

            .value {
                color: $dark;
                font-weight: 500;
                flex-grow: 1;
                max-width: 530px;
                align-items: flex-start;
            }
        }
    }

    .comment {
        font-style: italic;
        word-break: break-word;
    }

    .services {
        .services-info {
            flex-grow: 1;
        }
    }

    .rating {
        display: flex;

        .groups {
            display: flex;
            flex-wrap: wrap;
            width: 300px;
            flex-shrink: 0;
        }

        .rating-item {
            width: 50%;
            flex-shrink: 0;
            margin-top: 20px;

            &:nth-child(-n + 2) {
                margin-top: 0;
            }
        }

        .rating-comment {
            .label {
                color: $dark;
            }

            .value {
                color: $grey;
                padding-top: 5px;
                font-style: italic;
                word-break: break-word;
            }
        }
    }

    .photos-wrapper {
        max-width: 400px;
    }

    .prices {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .item {
            color: $dark;
            width: 100%;
            display: flex;
            justify-content: space-between;

            &.payment-type {
                .label {
                    font-family: 'UniNeue', 'Arial', sans-serif;
                    border-radius: 3px;
                    color: $dark-blue;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 8px;
                }
                .value {
                    color: $blue-pressed;
                }
            }

            &.total {
                font-family: 'UniNeue', 'Arial', sans-serif;
                border-radius: 3px;
                color: $dark;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;

                span:first-child {
                    color: $dark-blue;
                }
            }
        }
    }
    .time-offset {
        font-size: 13px;
        color: $grey;
        margin-left: 8px;
    }

    .services-info {
        position: relative;
    }

    .details-button {
        position: absolute;
        right: 0;
        top: 0;
    }

    hr {
        margin-top: 15px;
        border: 0;
        border-top: 1px solid #e5e9f1;
        width: 100%;
    }

    .amount,
    .pickup-date {
        color: #979ea6;
        display: inline-block;
        font-size: 12px;
        margin-left: 5px;
    }

    .pickup-date {
        font-size: 13px;
    }

    hr.bold {
        border-width: 2px;
    }

    .ca-confirmation {
        .ca-sign-info {
            border: 1px solid $orange;
            border-radius: 4px;
            margin-bottom: 8px;
            font-size: 14px;
            color: $orange;
            text-align: center;
            padding: 12px 0 12px 0;

            &.ready {
                color: $success;
                border: 1px solid $green;
            }
        }
    }

    .service-list {
        .item + .item {
            margin-top: 16px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .carrier-order-item {
        &__wrapper.wrap {
            max-width: 100%;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        .section {
            padding-top: 30px;

            &:first-child {
                padding-top: 0;
            }
        }

        &__header {
            .btns {
                .actions {
                    display: none;
                }

                .dropdown-documents {
                    margin: 0;
                }

                .header-dropdown {
                    margin-right: 20px;
                    margin-right: 0;

                    .download {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 34px;
                        height: 34px;
                        font-size: 19px;
                        color: $dark-blue;

                        &:hover {
                            color: #557ca2;
                        }

                        &:active {
                            color: #254566;
                        }
                    }

                    .list {
                        border: 2px solid $border-color;
                        padding: 5px 0;
                        background-color: #fff;

                        .arrow {
                            position: absolute;
                            top: -5px;
                            right: 11px;
                            width: 10px;
                            height: 5px;
                            overflow: hidden;

                            &:before {
                                position: absolute;
                                top: 1px;
                                left: 0;
                                display: block;
                                width: 6px;
                                height: 6px;
                                content: '';
                                border: 2px solid #e5e9f1;
                                transform: rotate(45deg);
                                background: #fff;
                            }
                        }

                        .item {
                            padding: 0 20px;
                            font-family: 'UniNeue';
                            color: $dark-blue;
                            font-weight: 700;
                            font-size: 14px;
                            padding: 5px 20px;
                            white-space: nowrap;
                            cursor: pointer;

                            &:hover {
                                background: $silver;
                            }

                            a {
                                color: $dark-blue;
                            }
                        }
                    }
                }
            }
        }

        .group {
            padding-top: 30px;

            > .item {
                flex-direction: column;

                .label {
                    width: auto;
                    padding-bottom: 5px;
                }

                .value {
                    width: auto;
                }
            }
        }

        .status {
            align-items: flex-start;

            .value {
                flex-direction: column;
                align-items: flex-start !important;
                width: 100% !important;

                .tip {
                    display: flex;
                    width: 100%;
                    padding-left: 0;
                    margin-top: 10px;

                    .tip-text {
                        flex-grow: 1;
                    }
                }

                .order-status-wrap {
                    width: 100%;
                }
            }
        }

        .services {
            .services-info {
                flex-grow: 1;
            }
        }

        .rating {
            flex-direction: column;

            .groups {
                width: 100%;
            }

            .rating-item {
                width: 50%;
                margin-top: 10px;
            }

            .rating-comment {
                padding-top: 15px;

                .label {
                    color: $dark;
                }

                .value {
                    color: $grey;
                    padding-top: 5px;
                    font-style: italic;
                }
            }
        }

        .service-list {
            .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                .info {
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 6px;
                    }
                }

                .value {
                    flex-grow: 0;
                }
            }
        }

        .photos-wrapper {
            margin-top: 8px;
        }

        .prices {
            align-items: flex-start;

            .item {
                &.total {
                    margin-right: 0;
                }
            }
        }
    }
}

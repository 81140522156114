@import 'styles/global.scss';

.dropdown {
    position: relative;
    cursor: auto;

    &.right {
        .list {
            right: 0;
            left: auto;
        }
    }

    .list {
        position: absolute;
        top: 100%;
        left: 0;
        text-align: left;
        z-index: 999;
        min-width: 100%;
        visibility: hidden;
        opacity: 0;

        &.opened {
            transition: opacity 0.3s ease;
            visibility: visible;
            opacity: 1;
        }
    }

    &.select .list {
        width: 100%;
    }

    &.disabled {
        .toggle {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .toggle:focus {
        .text-wrapper .text {
            border: solid 1px #519cec;
        }
    }
}

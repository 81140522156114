@import 'styles/global.scss';

@keyframes appearFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes hideToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.mobile-popup {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.42);
    visibility: hidden;
    opacity: 0;
    z-index: 200;

    &__content {
        background: white;
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        animation-fill-mode: both;
    }

    &.visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s ease-in-out;

        .mobile-popup__content {
            animation-name: appearFromRight;
            animation-duration: 0.5s;
        }
    }

    &.hidden {
        .mobile-popup__content {
            animation-name: hideToRight;
            animation-duration: 0.5s;
        }
    }
}

@import '@/assets/scss/global.scss';

.rating-item {
    text-align: left;
    font-size: 15px;

    .title {
        padding-bottom: 2px;
        color: $dark;
        display: flex;
        align-items: center;

        .hint {
            margin-left: 10px;
        }
    }

    > .list {
        pointer-events: none;
        display: flex;

        > div.item {
            margin-left: 6px;
            margin-top: 6px;

            &:first-child {
                margin-left: 0;
            }

            .icon-star {
                font-size: 13px;
                color: #e0e4ec;
            }

            &.active {
                .icon-star {
                    color: $red;
                }
            }
        }
    }

    &.small {
        .list .item .icon-star {
            font-size: 10px;
        }
    }

    &.large {
        .list .item .icon-star {
            font-size: 15px;
        }

        .title {
            padding-bottom: 10px;
        }
    }

    &.control {
        .list {
            pointer-events: auto;
        }

        .list .item {
            cursor: pointer;
        }
    }
}

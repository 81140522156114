@import 'styles/global.scss';

.olimp-sign-up {
  padding-top: 24px;
  width: 100%;
  flex: 1;
  height: 100%;
  background:white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  align-content: flex-start;

  .sign-up_form a {
    color: $blue-pressed;
    font-weight: 500;
  }

  &.delivery {
    .sign-up_description p {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  .button-block {
    display: flex;
    justify-content: space-between;

    > * {
      max-width: 48%;
    }
  }

  .sign-up_header {
    min-width: 272px;
    max-width: 312px;
    width: 100%;
    position: relative;

    .sign-up_back {
      position: absolute;
      top: 0;
      left: -12px;
      cursor: pointer;
    }

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .referral-description {
      font-size: 13px;
      text-align: center;
      p {
        margin: 0;
      }
    }
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0 0 0;
      font-size: 16px;
    }
    .progress {
      display: flex;
      height:4px;
      background: rgba(16, 24, 69, 0.12);
      width: 100%;
      margin: 12px 0;
      .progress-fill {
        display: flex;
        height:4px;
        background: #4B8EDE;
      }
    }
    svg path {
      fill: black;
    }
  }

  .sign-up_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 272px;
    max-width: 520px;
    width: 100%;
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      /* identical to box height */

      text-align: center;

      color: #000000;
      font-family: 'Avenir Next';

      margin-bottom: 16px;
    }
    p {
      margin: 8px 20px 20px 20px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-family: 'Avenir Next';

      &.extra {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        /* Black/black-85-text-primary */

        color: rgba(9, 21, 38, 0.85);

        opacity: 0.6;
      }
    }
  }
  .sign-up_form {
    min-width: 272px;
    max-width: 312px;
    width: 100%;
    flex: 1;
    > .loader {
      margin-top: 48px;
    }
    .checkbox {
      margin-top: 24px;
    }

    .checkbox .label {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      white-space: normal;
      opacity: 0.7;
    }

  }

  h3 {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  a.link {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    small {
      color: #091526;
      font-weight: 400;
      font-family: 'Avenir Next';
    }
  }

  a.link-blue {
    display: block;
    width: 100%;
    text-align: center;
    small {
      color: $blue;
      font-weight: 600;
      font-family: 'Avenir Next';
    }
  }

  .error-field > .error {
    font-size: 12px;
    color: $red;
  }

  .w-error > .error {
    white-space: nowrap;
  }

  .bottom-footer {
    display: flex;
    padding: 30px 0;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    small {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      &:hover {
        svg path {
          fill: black;
        }
      }
      a {
        max-width: 100px;
        font-size: 12px;
        line-height: 24px;
        svg {
          margin-bottom: -7px;

          path {

            fill: gray;
          }
        }
      }
    }
  }
}

.photo-frame {
    position: relative;
    padding: 0;

    &__status {
        padding: 2px 5px;

        position: absolute;
        z-index: 10;
        right: 4px;
        top: 5px;

        color: #ffffff;
        font-size: 10px;
        line-height: 14px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }

    &__inner {
        position: absolute;
        left: 0;
        bottom: 6px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 6px;
    }

    &__is-main {
        padding: 4px 10px;
        font-size: 10px;
        color: #ffffff;
        line-height: 14px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 7px;
        height: auto;
        cursor: pointer;

        &_active {
            pointer-events: none;
            background: #519cec;
        }

        &:focus:not(:disabled) {
            color: #ffffff !important;
        }
    }

    &__controls {
        visibility: hidden;
        pointer-events: none;

        &_showed {
            visibility: visible;
            pointer-events: all;
        }
    }

    &__button {
        padding: 0;
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
    }
}

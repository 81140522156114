@import '@/assets/scss/global.scss';

.landing {
    background: #fff;

    .disclaimer {
        position: fixed;
        bottom: 0;
        left: 0;
        opacity: 0.98;
        background-color: #fff;
        z-index: 100;
        width: 100%;
        height: auto;
        padding: 15px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        color: #979ea6;
        border-top: 1px solid #e5e9f1;
        overflow: auto;

    a {
      font-size: 13px;
      line-height: 1.31;
      color: #51585f;
      border-bottom: 1px solid #51585f;
      -webkit-transition: color .2s linear;
      transition: color .2s linear;
    }

    .wrapper > div {
      position: relative;
      padding: 0 20px;
    }

    .icon-close {
      position: absolute;
      right: 5px;
      top: -5px;
      cursor: pointer;
      font-size: 11px;
    }

        button {
            margin: 0 auto;
        }
    }

    .section {
        padding: 80px 10px;

        & > div {
            display: flex;
            justify-content: space-between;
        }

        .title {
            font-family: 'UniNeue';
            font-size: 32px;
            font-weight: bold;
            letter-spacing: -0.3px;
            color: $dark-blue;
            padding-bottom: 15px;
        }

        .description {
            font-family: 'AvenirNext';
            font-size: 16px;
            color: #6f8295;
            line-height: 1.75;
            padding-right: 100px;
            padding-bottom: 40px;
        }
    }

    .auth {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 590px;
        background: $light-blue;
        padding-top: 0;

        .heading {
            padding: 16px 0;
            font-family: 'UniNeue';
            margin-top: 12px;

            h1 {
                font-size: 26px;
                font-weight: normal;
                opacity: 0.7;
                max-width: 50%;
                color: $dark-blue;
                text-align: center;
            }
        }

        & > div {
            align-items: center;
        }

        .info {
            display: flex;
            flex-wrap: wrap;

            .heading-small {
                color: $dark-blue;
                font-size: 22px;
                font-weight: bold;
            }

            .types-radio {
                display: flex;
                color: $dark-blue;
                font-size: 32px;
                font-family: 'UniNeue';
                white-space: nowrap;

                .list {
                    display: flex;
                    align-items: flex-end;

                    .type {
                        position: relative;
                        cursor: pointer;
                        font-weight: bold;
                        letter-spacing: -0.2px;
                        margin: 0 15px;

                        &:not(.active) {
                            opacity: 0.7;
                            font-size: 18px;
                            padding-bottom: 6px;
                            transition: opacity 0.2s linear;

                            &:hover {
                                opacity: 1;
                            }

                            &:after {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                display: block;
                                width: 100%;
                                height: 2px;
                                content: '';
                                background-image: linear-gradient(to right, $dark-blue 65%, transparent 35%);
                                background-size: 6px 100%;
                            }
                        }

                        &.active {
                            border-bottom: 2px solid $blue;
                        }
                    }
                }
            }

            .description {
                padding: 40px 0;
                color: $dark-blue;
                font-style: italic;
                line-height: 1.2;
            }

            .btns {
                display: flex;

                button:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    .about {
        .right {
            padding-top: 30px;
        }
    }

    .features {
        background-color: $light-blue;
        max-width: 1300px;
        margin: 24px auto 0;
        padding-top: 24px;
        padding-bottom: 48px;

        & > div.row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 45px;

            &:nth-child(3) {
                justify-content: center;
            }

            > div.feature {
                padding: 0 20px;
                max-width: 260px;
                margin-bottom: 45px;
                text-align: center;

                > img {
                    display: inline;
                    margin-bottom: 25px;
                }

                > .text {
                    width: 100%;
                    padding: 0 20px;
                    font-family: 'AvenirNext';
                    font-size: 18px;
                }
            }
        }

        & > div.action-row {
            margin-top: 32px;
            display: flex;
            justify-content: flex-start;
        }
    }

    .video {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 390px;
        background: url('../../assets/images/landing-video.jpg') no-repeat 50% 50%;
        background-size: cover;

        .player {
            width: 100%;
            height: 100%;

            iframe {
                height: 100%;
            }
        }

        .controls {
            .play {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: 'UniNeue';
                font-size: 18px;
                font-weight: bold;
                letter-spacing: -0.2px;
                color: #f2f2f2;
                cursor: pointer;
                margin-top: 10px;
                transition: all 0.2s linear;

                &:hover {
                    .icon-play,
                    .title {
                        color: #fff;
                    }
                }

                .icon-play {
                    font-size: 72px;
                }

                .title {
                    padding-top: 10px;
                }
            }
        }
    }

    .why-choose-us {
        ul {
            li {
                position: relative;
                font-size: 18px;
                line-height: 1.33;
                color: $dark-blue;
                padding-bottom: 20px;

                &:before {
                    position: absolute;
                    top: 6px;
                    left: -20px;
                    content: '';
                    display: block;
                    background: url('../../assets/images/marker.svg') no-repeat;
                    width: 11px;
                    height: 9px;
                }
            }
        }

        .download {
            display: flex;
            padding-top: 50px;

            a {
                color: $dark-blue;
                display: flex;
                align-items: center;
                transition: color 0.2s linear;

                &:hover {
                    color: #557ca2;
                }

                &:active {
                    color: #254566;
                }

                .icon-download {
                    font-size: 21px;
                    margin-right: 15px;
                }

                .text {
                    font-family: 'UniNeue';
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: -0.1px;
                }
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .landing {
        .section {
            padding: 35px 20px;
            overflow: hidden;

            & > div {
                display: block;
            }

            .title {
                font-size: 20px;
            }

            .description {
                font-size: 15px;
                padding: 0 0 25px 0;
            }

            &.auth {
                height: auto;

                .heading {
                    margin-top: -24px;

                    h1 {
                        font-size: 18px;
                        max-width: 100%;
                    }
                }

                & > div {
                    .info {
                        .types-radio {
                            font-size: 20px;

                            .list {
                                .type {
                                    font-size: 14px;

                                    &.active {
                                        font-size: 20px;
                                        margin: 0 10px;
                                    }
                                }
                            }
                        }

                        .description {
                            font-size: 15px;
                            padding: 20px 0;
                        }

                        .btns {
                            padding-top: 25px;
                            justify-content: center;
                        }
                    }

                    .img {
                        background-size: contain;

                        img {
                            width: 100%;
                            height: auto;
                        }

                        &.carrier img {
                            width: 120%;
                        }
                    }
                }
            }

            &.about {
                & > div {
                    .left {
                        button {
                            width: 100%;
                        }
                    }

                    .img {
                        padding-bottom: 30px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            &.features {
                margin: 24px auto 0;
                padding-top: 24px;
                padding-bottom: 48px;

                & > div.row {
                    margin-top: 24px;
                    display: block;

                    > div.feature {
                        max-width: 100%;
                        margin-bottom: 24px;
                        text-align: center;
                        padding: 0 20px;

                        > img {
                            margin-bottom: 8px;
                        }

                        > .text {
                            font-size: 16px;
                            max-width: 80%;
                            margin: 0 auto;
                        }
                    }
                }

                & > div.action-row {
                    justify-content: center;
                }
            }

            &.why-choose-us {
                ul {
                    padding-left: 20px;

                    li {
                        font-size: 16px;
                    }
                }

                button {
                    width: 100%;
                }

                .download {
                    justify-content: center;
                    padding: 40px 0 10px 0;
                }
            }
        }

        .video {
            height: auto;
        }
    }
}

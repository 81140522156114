@import 'styles/global.scss';

.carrier-booking {
  display: grid;
  grid-template-areas:
    "details details details details details total total total"
    "warehouse warehouse warehouse warehouse warehouse total total total"
    "warehouse warehouse warehouse warehouse warehouse empty empty empty"
    ;
  gap: 16px;
  max-width: 1200px;
  margin: 32px auto 0;

  &__section {
    &--details {
      grid-area: details;
    }

    &--warehouse {
      grid-area: warehouse;
    }

    &--total {
      grid-area: total;
    }
  }

  &__item {
    display: flex;
    margin-top: 15px;

    &--photos {
      width: 100%;

      .photos-view {
        width: 100%;
      }

      .photos-view__preview, .photos-view__slide-inner {
          height: 392px;

          @media screen and (max-width: $media-limit-mobile) {
            height: 235px;
          }
      }

      .app-carousel-dots {
        position: relative;
        left: 0;
        bottom: 0;
        justify-content: flex-start;
        transform: none;

        @media screen and (max-width: $media-limit-mobile) {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          justify-content: center;
        }
      }

      .photo-thumb {
        display: block;
        width: 58px;
        height: 41px;
        border: 2px solid transparent;
        border-radius: 2px;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .slick-active .photo-thumb {
        border-color: #519CEC;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &.feature {
      width: 100%;
    }

    & > * {
      width: 50%;
      flex-shrink: 0;
    }

    .label {
      color: $grey;
    }

    .value {
      color: $dark;

      .time-offset {
        color: $grey;
      }
    }

    &.comment .value {
      font-style: italic;
      word-break: break-word;
    }


  }

  &__conditions {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: square outside;

    li {
      width: 100%;
      margin-top: 8px;
      color: $blue;
      padding-right: 16px;
    }
  }

  .warehouse-info {
    flex-grow: 1;
    font-size: 15px;
    padding-bottom: 20px;
    margin-bottom: 8px;

    &.services {
      .order-link {
        display: none;
      }
    }

    .section {
      position: relative;
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }

      .header {
        & > * {
          width: 40%;
        }

        & > *:first-child {
          width: 60%;
          margin-right: 16px;
        }

        .subtitle {
          font-size: 16px;
        }
      }
    }

    .details-container {
      display: flex;

      .details {
        width: 60%;

        &:first-child {
          margin-right: 16px;
        }

        &.features {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 420px;
          padding-left: 24px;
          border-left: 1px solid rgba(0, 0, 0, 0.05);
        }

        > .item {
          display: flex;
          margin-top: 15px;

          &--photos {
            width: 100%;

            .photos-view {
              width: 100%;
            }

            .photos-view__preview, .photos-view__slide-inner {
                height: 392px;

                @media screen and (max-width: $media-limit-mobile) {
                  height: 235px;
                }
            }

            .app-carousel-dots {
              position: relative;
              left: 0;
              bottom: 0;
              justify-content: flex-start;
              transform: none;

              @media screen and (max-width: $media-limit-mobile) {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                justify-content: center;
              }
            }

            .photo-thumb {
              display: block;
              width: 58px;
              height: 41px;
              border: 2px solid transparent;
              border-radius: 2px;

              img {
                width: 100%;
                height: auto;
                display: block;
              }
            }

            .slick-active .photo-thumb {
              border-color: #519CEC;
            }
          }

          &:first-child {
            margin-top: 0;
          }

          &.feature {
            width: 100%;
          }

          & > * {
            width: 50%;
            flex-shrink: 0;
          }

          .label {
            color: $grey;
          }

          .value {
            color: $dark;

            .time-offset {
              color: $grey;
            }
          }

          &.comment .value {
            font-style: italic;
            word-break: break-word;
          }

          &.conditions {
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            width: 100%;
            list-style: square outside;

            .value {
              width: 100%;
              margin-top: 8px;
              color: $blue;
              padding-right: 16px;
            }
          }
        }
      }
    }

    .scroll-help {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.2);
    }

    .prices {
      position: relative;
      height: 100%;
      max-height: 500px;
      overflow-y: auto;
      background: white;
    }
  }

  .info-container {
    display: flex;
    align-items: flex-start;

    .wrap {
      border-radius: 8px;
    }
  }
}

@media screen and (max-width: $media-limit-mobile) {
  .carrier-booking {
    grid-template-areas:
    "details"
    "warehouse"
    "total";
  }
  .warehouses-item.page {


    .info-container {
      .warehouse-info {

        .details {
          width: 100%;

          .item {
            display: flex;
            flex-direction: column;
            margin-top: 15px;

            & > * {
              width: 100%;
            }

            .label {
              padding-bottom: 5px;
            }

            &.comment .value {
              font-style: normal;
            }
          }
        }

        .prices {
          overflow: hidden;
          max-height: 100%;

          .item {
            padding: 10px 0;

            .title {
              margin-right: 10px;
              line-height: 23px;
            }

            .label {
              flex-wrap: wrap;
              align-items: flex-start;
            }

            .price {
              margin-right: 10px;
              margin-left: 0;
            }

            .value {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .item.rating {
    width: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

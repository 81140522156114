@import "styles/global.scss";
.modal-upload {
  .modal-content .btns {
    margin-top: 0;
  }
  .modal-content .form-upload  .description {
    color: $dark-blue;
    font-size: 15px;
    width: 100%;
    padding-top: 12px;
    /* display: block; */
    padding-right: 0;
    display: block;
    text-align: center;
  }
  .upload-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px;
    gap: 12px;

    width: 100%;
    min-height: 220px;

    background: $light-silver;
    border: 1px dashed $blue;
    border-radius: 4px;
    > *:not(button) {
        pointer-events: none;
    }

    &.dragActive {

      background: $silver;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: black;

    }

    .gray-txt {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;

      color: rgba(0, 0, 0, 0.6);
      max-width: 325px;


    }

  }

  .modal-upload_files {
    display: flex;
    margin: 12px 0;
    flex-direction: column;
    .file-error {
      color: $error;
      font-size: 14px;
    }
    .modal-upload_file {
      position: relative;
      background-color: $light-silver;
      width: 100%;
      padding: 12px 8px 12px 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        flex: 1;
      }
      > svg {
        margin-right: 8px;
        margin-left: -4px;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .upload-field {
    input {
      display: none;
      visibility: hidden;
    }
  }
}

@import 'styles/global.scss';

.carrier-warehouse-info {
    flex-grow: 1;
    font-size: 15px;
    padding-bottom: 20px;
    margin-bottom: 24px;
    &__item {
        display: flex;
        margin-top: 15px;

        &--photos {
            width: 100%;

            .photos-view {
                width: 100%;
            }

            .photos-view__preview,
            .photos-view__slide-inner {
                height: 392px;

                @media screen and (max-width: $media-limit-mobile) {
                    height: 235px;
                }
            }

            .app-carousel-dots {
                position: relative;
                left: 0;
                bottom: 0;
                justify-content: flex-start;
                transform: none;
                height: 48px;
                margin-top: 0;

                @media screen and (max-width: $media-limit-mobile) {
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    justify-content: center;
                }
            }

            .photo-thumb {
                display: block;
                width: 58px;
                height: 41px;
                border: 2px solid transparent;
                border-radius: 2px;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .slick-active .photo-thumb {
                border-color: #519cec;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &--feature {
            width: 100%;
        }

        & > * {
            width: 50%;
            flex-shrink: 0;
        }
    }

    &__label {
        color: $grey;
    }

    &__value {
        color: $dark;

        .time-offset {
            color: $grey;
        }
    }

    &__conditions {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        list-style-image: url('../../../../assets/images/ic-tick.svg');

        li {
            width: 100%;
            padding-right: 16px;
            padding-left: 12px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #000000;
        }
    }

    &.services {
        .order-link {
            display: none;
        }
    }

    .section {
        position: relative;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }

        .header {
            & > * {
                width: 40%;
            }

            & > *:first-child {
                width: 60%;
                margin-right: 16px;
            }

            .subtitle {
                font-size: 16px;
            }
        }
    }

    .details {
        width: 60%;

        &:first-child {
            margin-right: 16px;
        }

        &.features {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 16px;
            max-width: 420px;
            padding-left: 24px;
            border-left: 1px solid rgba(0, 0, 0, 0.05);
        }

        > .item {
            display: flex;
            margin-top: 15px;

            &--photos {
                width: 100%;

                .photos-view {
                    width: 100%;
                }

                .photos-view__preview,
                .photos-view__slide-inner {
                    height: 392px;

                    @media screen and (max-width: $media-limit-mobile) {
                        height: 235px;
                    }
                }

                .app-carousel-dots {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    justify-content: flex-start;
                    transform: none;

                    @media screen and (max-width: $media-limit-mobile) {
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                        justify-content: center;
                    }
                }

                .photo-thumb {
                    display: block;
                    width: 58px;
                    height: 41px;
                    border: 2px solid transparent;
                    border-radius: 2px;

                    img {
                        width: 100%;
                        height: auto;
                        display: block;
                    }
                }

                .slick-active .photo-thumb {
                    border-color: #519cec;
                }
            }

            &:first-child {
                margin-top: 0;
            }

            &.feature {
                width: 100%;
            }

            & > * {
                width: 50%;
                flex-shrink: 0;
            }

            .label {
                color: $grey;
            }

            .value {
                color: $dark;

                .time-offset {
                    color: $grey;
                }
            }

            &.conditions {
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                width: 100%;
                list-style: square outside;

                .value {
                    width: 100%;
                    margin-top: 8px;
                    color: $blue;
                    padding-right: 16px;
                }
            }
        }
    }

    .scroll-help {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.2);
    }

    .prices {
        position: relative;
        height: 100%;
        max-height: 500px;
        overflow-y: auto;
        background: white;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .carrier-warehouse-info {
        &__details-list {
            width: 100%;
        }

        &__item {
            display: flex;
            flex-direction: column;
            margin-top: 15px;

            & > * {
                width: 100%;
            }
        }

        &__label {
            padding-bottom: 5px;
        }
    }
}

.register-form {
    h2 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: -.3px;
        color: #416283;
        padding-bottom: 0px;
        text-align: center;
    }

    .description {
        text-align: center;
        padding-bottom: 0px;
    }
}

@import '@/assets/scss/global.scss';

.radio {
    color: $dark;

    .radio-loader {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .title {
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.1px;
        color: $dark;
        text-transform: uppercase;
        padding-bottom: 12px;
        text-align: left;
    }

    .list {
        display: flex;

        .item {
            display: flex;
            align-items: center;
            cursor: pointer;

            .control {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                background-color: #f7f8fa;
                border: solid 1px #e2e7ed;
                border-radius: 50%;

                &:before {
                    display: none;
                    width: 6px;
                    height: 6px;
                    content: '';
                    border-radius: 50%;
                    background-color: $blue;
                }
            }

            &.active {
                .control {
                    border: solid 1px $blue;
                    border-radius: 50%;

                    &:before {
                        display: block;
                    }
                }
            }

            .label {
                font-size: 15px;
                font-weight: normal;
                margin-left: 10px;
                white-space: nowrap;
                text-transform: none;
                padding-bottom: 0;
            }
        }
    }

    &.wrap-options {
        padding-bottom: 24px;
    }

    &.wrap, &.wrap-options {
        .list {
            .item {
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &.column {
        .list {
            flex-direction: column;
        }
    }

    &.column {
        .list {
            flex-direction: column;

            .item {
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .radio {
        &.wrap, &.wrap-options {
            .list {
                flex-wrap: wrap;

                .item {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@import 'styles/global.scss';

.warning {
    background-color: #ffe699;
}

.user-status-info {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
}

@media screen and (max-width: $media-limit-mobile) {
    .dev .user-status-info {
        top: 108px;
    }

    .user-status-info {
        top: 65px;
    }
}

@import '@/assets/scss/global.scss';

.table-list-wrapper {
    .list {
        .cell {
            flex-shrink: 0;
            padding-right: 20px;
            display: flex;

            &:last-child {
                padding-right: 0;
            }

            &-id {
                width: 70px;
            }

            &-entity {
                width: 140px;
            }

            &-email {
                width: 220px;

                &-truncated span {
                    max-width: 200px;
                    padding-right: 20px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-first-name {
                width: 150px;

                &-truncated span {
                    max-width: 150px;
                    padding-right: 20px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-last-name {
                width: 150px;

                &-truncated span {
                    max-width: 150px;
                    padding-right: 20px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-phone {
                width: 140px;
            }

            &-type {
                width: 100px;
            }

            &-address {
                width: 270px;
            }

            &-entity-name {
                width: 200px;
                word-break: break-word;
            }

            &-manager {
                width: 200px;
            }

            &-orders {
                justify-content: center;
                width: 200px;
            }

            &-date {
                width: 100px;
            }

            &-status {
                width: 180px;
            }

            &-services {
                width: 110px;
            }

            &-total {
                width: 120px;
            }

            &-distance {
                width: 130px;
            }

            &-rating {
                width: 130px;
            }

            &-amount {
                width: 90px;
            }

            &-days {
                width: 100px;
            }

            &-contacts {
                width: 80px;
            }

            &-time {
                width: 190px;
            }

            &-button {
                flex-grow: 1;

                a {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }

            &-photo {
                width: 74px;
            }
        }

        .header {
            display: flex;
            align-items: center;
            height: 48px;
            background-color: #dbe2ef;
            border-radius: 4px;
            user-select: none;
            padding: 0 30px;

            .item {
                position: relative;
                color: $dark;
                font-size: 11px;
                pointer-events: none;
                text-transform: uppercase;
                border-radius: 3px;
                text-align: left;
                font-weight: 500;
                letter-spacing: -0.1px;
                white-space: nowrap;

                .visible {
                    position: absolute;
                }

                .bold {
                    position: relative;
                    opacity: 0;
                    font-weight: 600;
                    white-space: nowrap;
                }
            }

            .sort {
                cursor: pointer;
                pointer-events: auto;
                padding: 5px 20px 5px 0;

                &:hover {
                    color: $dark;
                    font-weight: 600;
                }

                &:after,
                &:before {
                    position: absolute;
                    right: 6px;
                    width: 7px;
                    height: 4px;
                    opacity: 0.5;
                    content: '';
                    background-image: url('../../../assets/images/icon-sort.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                &:before {
                    bottom: 7px;
                }

                &:after {
                    top: 7px;
                    transform: rotateX(180deg);
                }

                &.asc,
                &.desc {
                    padding-left: 10px;
                    color: $dark;
                    background: #c2ccdd;
                    font-weight: 600;
                }

                &.asc {
                    &:before {
                        opacity: 1;
                    }
                }

                &.desc {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        .data {
            .row {
                display: flex;
                align-items: center;
                min-height: 62px;
                padding: 0 30px;
                font-size: 15px;
                color: $dark;
                border-radius: 4px;
                background-color: #fff;
                margin-top: 4px;

                .cell {
                    .label {
                        display: none;
                    }

                    &-entity {
                        .name {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    &-services {
                        .icons {
                            display: flex;
                            align-items: center;
                        }

                        .icon-service {
                            position: relative;
                            margin-right: 5px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &:hover {
                                .tooltip {
                                    opacity: 1;
                                    visibility: visible;

                                    .details {
                                        color: $grey;
                                    }
                                }
                            }

                            .tooltip {
                                left: -18px;
                                white-space: nowrap;
                            }
                        }
                    }

                    &-status {
                        display: block;

                        &.has-prev {
                            padding-left: 15px;
                        }

                        .prev-status {
                            position: relative;
                            font-size: 11px;
                            letter-spacing: -0.1px;
                            color: $grey;
                            padding-bottom: 5px;

                            .icon-arrow-long {
                                display: none;
                            }

                            .icon-arrow-round {
                                position: absolute;
                                top: 9px;
                                left: -12px;
                                font-size: 20px;
                                opacity: 0.6;
                            }
                        }
                    }

                    &-contacts {
                        .contacts {
                            position: relative;
                            width: 22px;
                            height: 22px;
                            border-radius: 50%;
                            border: 1px solid $grey-blue;
                            color: $grey-blue;
                            text-align: center;
                            line-height: 20px;
                            cursor: pointer;
                            transition: all 0.2s linear;

                            &:hover {
                                background: $grey-blue;
                                color: #fff;

                                .tooltip {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }

                            .tooltip {
                                top: -2px;
                                left: calc(100% + 6px);
                                max-width: 300px;

                                .arrow {
                                    top: 8px;
                                    left: -7px;
                                    transform: rotate(-90deg);
                                }
                            }

                            .contacts-list {
                                width: 100%;
                                text-align: left;
                                font-size: 13px;
                                color: $dark;

                                .item {
                                    display: flex;
                                    align-items: center;
                                    padding: 2px 0;
                                    white-space: nowrap;
                                    transition: none;

                                    .value {
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }

                                    .icon {
                                        width: 20px;
                                        flex-shrink: 0;
                                        color: $grey-blue;

                                        &-user {
                                            font-size: 10px;
                                        }

                                        &-phone {
                                            font-size: 11px;
                                        }

                                        &-mail {
                                            font-size: 8px;
                                            margin-top: 3px;
                                        }

                                        &-license {
                                            font-size: 9px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.collapse {
        .list {
            .header {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .data {
                .row {
                    margin: 0;
                    border-left: 1px solid $border-color;
                    border-right: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                    border-radius: 0;

                    &:last-child {
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }

    .status {
        padding: 15px 0;
        border-radius: 4px;
        background-color: #dbe2ef;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: $dark;
    }

    .table-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .sort-control {
        .label {
            font-size: 11px;
            font-weight: 500;
            letter-spacing: -0.1px;
            color: #5c6064;
            text-transform: uppercase;
            padding-bottom: 5px;
        }
    }
}

@mixin bordered() {
    padding: 10px 0;
    margin: 10px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

@media screen and (max-width: $media-limit-mobile) {
    .table-list-wrapper {
        .list {
            .cell {
                flex-shrink: 0;
                padding-right: 20px;
                display: flex;

                &:last-child {
                    padding-right: 0;
                }

                &[class^='cell-'],
                &[class*=' cell-'] {
                    width: auto;
                }
            }

            .sort-control {
                padding-bottom: 20px;
            }

            .header {
                display: none;
            }

            .data {
                .row {
                    align-items: stretch;
                    width: 100%;
                    height: auto;
                    padding: 15px;
                    flex-wrap: wrap;
                    margin-top: 10px;

                    &:first-child {
                        margin-top: 0;
                    }

                    &.table-line-carrier-order {
                        .cell {
                            display: flex;

                            &-id {
                                order: 1;
                                min-width: 50px;
                            }

                            &-date {
                                order: 2;
                                min-width: 100px;
                            }

                            &-total {
                                order: 3;
                                flex:1;
                                display: flex;
                                align-items: flex-end;
                                font-weight: 500;
                                span {
                                    align-items: flex-end;
                                }
                            }

                            &-address {
                                order: 4;
                            }

                            &-entity-name {
                                order: 2;
                            }

                            &-manager {
                                order: 3;
                            }

                            &-status {
                                width: 50%;
                                order: 5;
                            }

                            &-services {
                                width: 50%;
                                order: 6;
                                padding-left: 10px;
                            }

                            &-button {
                                order: 7;
                            }
                        }
                    }

                    &.table-line-warehouse-order {
                        .cell {
                            display: flex;

                            &-id {
                                order: 1;
                                min-width: 50px;
                            }

                            &-date {
                                order: 2;
                                min-width: 100px;
                            }

                            &-total {
                                min-width: calc(100% - 160px);
                                order: 3;
                                margin: 8px 0;
                                font-weight: 500;
                                flex:1;
                                display: flex;
                                align-items: flex-end;
                                span {
                                    align-items: flex-end;
                                }
                            }

                            &-amount {
                                order: 4;
                                min-width: 30%;

                                @include bordered;
                            }

                            &-days {
                                order: 5;
                                min-width: 30%;

                                @include bordered;
                            }

                            &-services {
                                width: 40%;
                                order: 6;
                                padding-left: 10px;

                                @include bordered;
                            }

                            &-status {
                                width: 100%;
                                order: 7;
                                border-bottom: 1px solid $border-color;
                                padding-bottom: 10px;
                            }

                            &-contacts {
                                order: 8;
                                padding-top: 10px;
                            }

                            &-button {
                                order: 9;
                            }
                        }
                    }

                    &.table-line-modal-orders {
                        .cell {
                            display: flex;

                            &-id {
                                order: 1;
                                min-width: 60px;
                                padding-bottom: 20px;
                            }

                            &-time {
                                width: calc(100% - 70px);
                                order: 2;
                                padding-bottom: 20px;
                            }

                            &-amount {
                                order: 3;
                                min-width: 60px;
                            }

                            &-services {
                                min-width: 70px;
                                order: 4;
                            }

                            &-total {
                                width: calc(100% - 130px);
                                order: 5;
                            }

                            &-contacts {
                                padding-top: 20px;
                                order: 6;
                            }

                            &-button {
                                order: 7;

                                button {
                                    width: auto;
                                    margin: 0;
                                }
                            }
                        }
                    }

                    .cell {
                        flex-direction: column;
                        max-width: 100%;

                        .label {
                            display: block;
                            color: $grey;
                            text-transform: uppercase;
                            font-size: 11px;
                            font-weight: 500;
                            padding-bottom: 5px;
                        }

                        &-entity {
                            .name {
                                white-space: normal;
                            }
                        }

                        &-address {
                            width: 100%;
                            padding: 10px 0;
                            margin: 10px 0;
                            border-top: 1px solid $border-color;
                            border-bottom: 1px solid $border-color;
                        }

                        &-distance,
                        &-total {
                            padding-right: 15px;
                        }

                        &-button {
                            width: 100%;
                            padding-top: 20px;

                            button {
                                width: 100%;
                                height: 36px;
                                margin: 0 auto;
                                border-width: 2px;
                                line-height: 34px;
                            }
                        }

                        &-status {
                            &.has-prev {
                                padding-left: 0 !important;

                                .status-wrap {
                                    flex-direction: row-reverse;
                                }
                            }

                            .status-wrap {
                                display: flex;
                                align-items: center;
                                flex-shrink: 0;

                                .order-status-wrap {
                                    width: 50%;
                                }

                                .prev-status {
                                    display: flex;
                                    align-items: center;
                                    width: 50%;
                                    padding-left: 10px;
                                    font-size: 13px;

                                    span {
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                    }

                                    .icon-arrow-round {
                                        display: none;
                                    }

                                    .icon-arrow-long {
                                        display: block;
                                        width: 15px;
                                        font-size: 8px;
                                        margin-right: 5px;
                                        flex-shrink: 0;
                                    }
                                }
                            }
                        }

                        &-status {
                            display: block;

                            &.has-prev {
                                padding-left: 15px;
                            }

                            .prev-status {
                                position: relative;
                                font-size: 11px;
                                letter-spacing: -0.1px;
                                color: $grey;
                                padding-bottom: 5px;

                                .icon-arrow-round {
                                    position: absolute;
                                    top: 9px;
                                    left: -12px;
                                    font-size: 20px;
                                    opacity: 0.6;
                                }
                            }
                        }

                        &-services {
                            .tooltip {
                                left: -18px;
                                white-space: normal !important;
                                line-height: 15px;
                            }
                        }

                        &-contacts {
                            width: 100%;
                            order: 8;

                            .contacts {
                                width: auto;
                                height: auto;
                                border-radius: 0;
                                border: none;
                                background-color: transparent !important;
                            }

                            .contacts-icon {
                                display: none;
                            }

                            .tooltip {
                                position: relative;
                                left: auto !important;
                                display: flex;
                                padding: 0;
                                visibility: visible;
                                opacity: 1;
                                border: none;
                                box-shadow: none;
                                background: transparent;
                                max-width: none !important;

                                .arrow {
                                    display: none;
                                }
                            }
                        }

                        &-photo {
                            width: 100%;
                            margin-bottom: 10px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        &.collapse {
            .list {
                .data {
                    .row {
                        border: none;
                        border-bottom: 1px solid $border-color;
                        padding: 0;
                        margin-bottom: 20px;
                        padding-bottom: 20px;

                        &:last-child {
                            border-bottom: none;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        .table-footer {
            .table-sort-pagination {
                justify-content: space-between;
                width: 100%;
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.photos-view {
  width: 100%;
  text-align: center;

  &__preview {
    position: relative;
    width: 73px;
    height: 54px;

    @media (max-width: $media-limit-mobile) {
      width: 100%;
      height: 223px;
    }
  }

  &__tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 1;
    padding-bottom: 5px;
    width: 300px;
    height: 228px;

    .photos-view__slide-inner {
      height: 223px;
    }
  }

  &__bg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &--empty {
      background-image: url('../../assets/images/photo-empty-frame.svg');
      top: 0;
      transform: none;
      background-size: 100% 100%;
    }
  }

  &__preview,
  &__slide-inner {
    position: relative;
    width: 100%;

    @media (max-width: $media-limit-mobile) {
      width: 100%;
      height: 223px;
    }
  }

  &__slide-inner {
    width: 100%;
    height: 263px;
  }

  &__img-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }
  &__img-title {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    color: $grey;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;

    @media (max-width: $media-limit-mobile) {
      max-width: calc(100% - 32px);
      bottom: 32px;
    }
  }
}

@import 'styles/global.scss';

.modal-location {
    .map-wrapper {
        position: relative;
        width: 100%;
        height: 360px;

        .map {
            width: calc(100% + 60px);
            height: 100%;
            margin-left: -30px;

            .loader {
                width: 32px;
                height: 32px;
                .spinner {
                    margin-left: -16px;
                    margin-top: -16px;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .modal-location {
        .map-wrapper {
            height: 250px;

            .map {
                width: calc(100% + 30px);
                height: 100%;
                margin-left: -15px;
            }
        }
    }
}

@import 'styles/global.scss';

.mobile-fixed-banner {
  display: none;

  @media screen and (max-width: $media-limit-mobile) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 24px;
    background: #FFFFFF;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.05);
    z-index: 100;
    button.large {
      padding: 0 10px;
      font-size: 14px;
    }
  }
}

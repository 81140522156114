@import '@/assets/scss/global.scss';

.checkbox {
    display: flex;
    cursor: pointer;
    color: $dark;

    &:focus .control {
        border: solid 1px #519cec;
    }

    .control {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: #f7f8fa;
        border: solid 1px #e2e7ed;
        flex-shrink: 0;

        .icon-checkbox {
            display: none;
            font-size: 6px;
            color: #fff;
        }
    }

    .text {
        padding-left: 8px;
    }

    .label {
        font-size: 15px;
        color: $dark;
        display: inline;
        font-weight: normal;
        white-space: nowrap;
        text-transform: none;
    }

    .hint {
        display: inline-block;
        margin-left: 6px;
    }

    &.active {
        .control {
            border-color: $blue;
            background-color: $blue;

            .icon-checkbox {
                display: block;
            }
        }
    }

    &.disabled {
        pointer-events: none;

        .label {
            color: #9ea0a2;
        }
    }

    &.highlighted {
        .label {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.rating {
    display: flex;
    align-items: center;

    .details {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: #e4ebf6;
        border-radius: 50%;
        margin-left: 20px;
        font-size: 12px;
        color: #677381;
        text-align: center;
        line-height: 16px;
        font-weight: 500;
        cursor: default;

        &:hover {
            .tooltip {
                opacity: 1;
                visibility: visible;
            }
        }

        .tooltip {
            left: calc(50% - 115px);
            width: 230px;
            padding: 15px;

            .arrow {
                left: calc(50% - 5px);
            }

            .groups {
                display: flex;
                flex-wrap: wrap;
            }

            .rating-item {
                width: 50%;
                text-align: left;
                margin-top: 20px;
                font-size: 13px;

                &:nth-child(-n + 2) {
                    margin-top: 0;
                }

                &:nth-child(-n + 2) {
                    margin-top: 0;
                }
            }
        }
    }
}

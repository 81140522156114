@import 'styles/global.scss';
.sign-up_ready {
  small {
    font-family: 'AvenirNext';
    font-weight: 400;
    color: $grey;
    display: block;
    width: 100%;
    text-align: center;
    margin: 16px 0 10px 0;
  }
}
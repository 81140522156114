@import '@/assets/scss/global.scss';

.dropdown-menu {
    text-align: left;

    &__dropdown {
        height: 48px;

        .slot {
            display: none;
        }

        &.opened {
            .icon-arrow {
                transform: rotate(180deg);
            }
        }
    }

    &__toggle {
        cursor: pointer;
        width: 100%;
        padding: 10px 14px !important;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        overflow: visible;
        text-align: left;

        .icon-arrow {
            margin-left: auto;
        }
    }

    &__label {
        display: flex;
        align-items: center;
    }

    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 4px 6px 24px rgba(65, 98, 131, 0.25);
        border-radius: 8px;
        padding: 4px 0;
        font-size: 14px;
    }

    &__item {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $dark-blue;
        padding: 10px 14px;

        a {
            display: block;
            color: inherit;
        }

        &:hover,
        &.active,
        &.router-link-active {
            background: $silver;
        }

        &--border-top {
            border-top: 1px solid $border-color;
        }

        &--border-bottom {
            border-bottom: 1px solid $border-color;
        }

        &--no-hover {
            pointer-events: none;
        }

        &--to-bottom {
            margin-top: auto;
        }
    }

    &__label {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: $dark-blue;
        margin-right: 8px;
        width: 100%;
    }

    .icon-arrow {
        font-size: 6px;
    }
}

.app-carousel-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 16px;
  height: 24px;

  > *:not(:last-child) {
    margin-right: 5px;
  }

  &__item {
    width: 27px;
    height: 4px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &--active {
      background: #519CEC;
    }
  }
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }
}

@import 'styles/global.scss';

.service {
    &:not(.main) {
        .item {
            margin-bottom: 12px;
        }
    }

    &.active {
        .item {
            .label {
                .control-radio {
                    border: solid 1px $blue;
                    border-radius: 50%;

                    &:before {
                        display: block;
                    }
                }
            }
        }
    }

    &.changeable .label {
        cursor: pointer;
    }

    .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .control-radio {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            min-width: 16px;
            height: 16px;
            background-color: #f7f8fa;
            border: solid 1px #e2e7ed;
            border-radius: 50%;
            margin-right: 8px;

            &:before {
                display: none;
                width: 6px;
                height: 6px;
                content: '';
                border-radius: 50%;
                background-color: $blue;
            }
        }

        .hint {
            margin-right: 10px;
        }

        .label {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
                display: flex;
                align-items: center;
            }
        }

        .price {
            line-height: 23px;
            border-radius: 2px;
            background-color: #f3f6fc;
            font-size: 12px;
            letter-spacing: normal;
            color: $grey;
            padding: 0 7px;
            margin-left: 10px;
        }

        .value {
            font-weight: 600;
        }
    }

    .rates {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .rate {
            position: relative;
            flex-direction: column;
            align-items: center;
            align-content: center;
            text-align: center;
            border-bottom: none;
            font-size: 12px;
            color: $border-color;
            max-width: 120px;

            &:not(:last-child) {
                margin-right: 32px;
            }

            &.active {
                color: $main;
            }

            .period {
                position: absolute;
                top: -16px;
                font-size: 10px;
                margin-bottom: 4px;
                white-space: nowrap;
                color: $grey;
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
                text-align: center;
            }

            .value {
                width: 100%;
                padding: 4px 8px;
                border-radius: 2px;
                background: #fafafa;
                font-weight: 600;
                border-top: 1px solid $silver;
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .service {
        .rates {
            flex-direction: column;

            > .rate {
                width: 100%;
                height: 48px;

                .period {
                    transform: none;
                    left: 0;
                }
            }
        }

        .item {
            flex-direction: column;

            .value {
                width: 100%;
                padding: 4px 8px;
                border-radius: 2px;
                font-weight: 500;
                border-top: 1px solid $silver;
                background: #fafafa;
                text-align: center;
                margin-top: 4px;
            }
        }
    }
}

@import 'styles/global.scss';

.carrier-booking-form {
    display: grid;
    grid-template-areas:
      "details details details details details total total total"
      "warehouse warehouse warehouse warehouse warehouse total total total"
      ;
    gap: 16px;
    max-width: 1200px;
    margin: 32px auto 0;

    &__section {
        &--details {
          grid-area: details;
        }

        &--warehouse {
          grid-area: warehouse;
        }

        &--total {
          grid-area: total;
        }
    }

    .form-group {
        margin-top: 30px;

        > .field + .field {
            margin-top: 20px;
        }
    }

    .list {
        .item {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
            height: 19px;

            &:first-child {
                margin-top: 0;
            }

            &.hidden {
                opacity: 0;
            }
        }
    }

    .field-row {
        display: flex;
        gap: 16px;

        .field {
            position: relative;
            flex-grow: 1;
            width: 50%;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .field {
        margin: 0;

        .label {
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            padding: 0 10px 0 0;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        .description {
            margin-bottom: 16px;
            font-size: 10px;

            &.green {
                color: $green;
                font-weight: 600;
                font-size: 12px;
            }
        }
    }

    .distribution-address {
        margin-top: 20px;
    }

    .comment {
        margin-top: 16px;
    }

    .days-count {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: #8f959b;
        padding: 5px 0 0 15px;
    }

    .prices {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }

            .name {
                font-size: 14px;
                color: $dark;
            }

            .value {
                font-size: 15px;
                font-weight: 500;
                color: $dark;
            }

            &.total {
                .name {
                    font-size: 15px;
                }

                .value {
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }
    .distance-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
        span {
            font-size: 12px;
            &:last-child {
                font-weight: 500;
                color: $black;
            }
        }
    }

    .time-interval {
        margin-top: 12px;

        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }

        .time-offset {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 12px;
        }

        .field {
            .label {
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                color: #000000;
                padding: 0 10px 0 0;
                margin-bottom: 4px;
            }

            .select {
                flex-grow: 1;
            }
        }
    }

    &__cost {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #000000;
    }

    &__order-params {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
    }

    &__summary {
        margin-top: 16px;

        dt {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 2px;
        }

        dd {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin: 0;
            margin-bottom: 16px;
        }
    }

    &__submit {
        margin-top: 24px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .carrier-booking-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-shadow: none;

        .booking-mobile-btn {
            min-width: 100px;
        }

        &__section {
            &--details {
              order: 0;
            }

            &--warehouse {
              order: 1;
            }

            &--total {
              order: 2;
            }
        }

        .field-row {
            display: flex;
            flex-direction: column;

            .field {
                width: 100%;
                margin-left: 0;
                margin-top: 15px;

                &:first-child {
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }

        .prices {
            padding-bottom: 0;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }

                .name {
                    font-size: 14px;
                    color: $grey;
                }

                .value {
                    font-size: 15px;
                    font-weight: 500;
                    color: $grey;
                }

                &.total {
                    .name {
                        color: $dark;
                        font-size: 15px;
                    }

                    .value {
                        color: $dark;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }

        &__banner-inner {
            display: flex;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            gap: 4px;
        }

        &__banner-summary {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

@import '@/assets/scss/global.scss';

.form.form-cost-adjustment {
    min-width: 270px;
    .row {
        display: flex;

        .field {
            width: 50%;
            padding: 0 20px 0 0;
        }
    }

    .field:not(:last-child) {
        margin-bottom: 22px;
    }

    .field.required {
        .label:after {
            content: '*';
            color: #416283;
        }
    }

    .btns {
        display: flex;
        justify-content: space-between;

        button {
            flex-grow: 1;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    .icon-download {
        font-size: 19px;
        color: #416283;
        cursor: pointer;
        padding: 0 10px;
        transform: rotate(180deg);
        display: inline-flex;
    }

    .service-document-control {
        display: flex;
    }

    .cost-adjustment-document-control {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 40px 0;

        .cost-adjustment-document__loaded-doc {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    .gg-close-o {
        color: #ef8473;
        cursor: pointer;
        display: inline-flex;
        margin-left: 3px;
    }

    .media-mobile {
        display: block;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .form-cost-adjustment .row {
        flex-direction: column;

        .field {
            width: 100%;
            padding-right: 0;
        }
    }
}

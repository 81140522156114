@import '@/assets/scss/global.scss';

.company-logo {
    margin-right: 24px;

    img {
        height: 35px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .company-logo {
        img {
            height: 45px;
        }
    }
}

@import '@/assets/scss/global.scss';

.input-text {
    .label {
        font-family: AvenirNext;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: -0.1px;
        color: $dark;
        text-transform: uppercase;
        padding-bottom: 7px;
        text-align: left;
        white-space: nowrap;
    }

    .input-wrapper {
        margin-bottom: 8px;
        .label-wrap {
            display: flex;
            align-items: center;
        }

        .w-error {
            min-height: 16px;
            padding: 4px 0 0 0;
            line-height: 1;

            .error {
                font-size: 12px;
                color: #ed6868;
            }
        }
    }

    .pw-eye {
        position: absolute;
        right: 13px;
        top: 6px;
        opacity: 1;
        padding: 4px;
        cursor: pointer;
        &.hidden span {
            opacity: 0.2;
        }
    }

    input {
        width: 100%;
        height: 40px;
        padding: 7px 10px;
        font-family: 'AvenirNext';
        font-size: 15px;
        border-radius: 3px;
        background-color: #f7f8fa;
        border: solid 1px $border-color;
        line-height: 24px;
        color: $dark;

        //&:focus {
        //  border: solid 1px $active
        //}

        @include placeholder() {
            color: #979ea6;
        }
    }

    &.focused {
        input {
            border: solid 1px #519cec;
            color: #252729;
        }
    }

    &.disabled {
        pointer-events: none;

        .label {
            color: #9ea0a2;
        }

        input {
            background-color: #fbfbfc;
            border: solid 1px #f7f8fa;
            color: #bdc3cb;
        }
    }

    &.has-error {
        input {
            border-color: $error;
        }
    }

    .control {
        position: relative;

        .icon {
            position: absolute;
            right: 0;
            top: 0;
        }

        .status {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;

            &:before {
                display: block;
                width: 6px;
                height: 6px;
                content: '';
                border-radius: 50%;
            }

            &.unconfirmed:before {
                background-color: #ff5656;
                box-shadow: 0 0 4px 0 #ff5656;
            }

            &.confirmed:before {
                background-color: #57e08c;
                box-shadow: 0 0 4px 0 #57e08c;
            }

            &.on-verification:before {
                background-color: #f5d922;
                box-shadow: 0 0 4px 0 #f5d922;
            }
        }
    }

    &.location,
    &.has-status,
    &.has-icon {
        input {
            padding-right: 40px;
        }
    }

    &.white {
        input {
            background-color: $white;
        }
    }

    &.small {
        input {
            height: 34px;
        }
    }

    &.has-icon {
        .icon {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 100%;
            color: $dark-blue;

            &.disabled {
                pointer-events: none;
                color: rgba(81, 81, 95, 0.4);
            }

            &:hover {
                color: #557ca2;
            }

            &:active {
                color: #254566;
            }

            &-text-search {
                font-size: 13px;
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.navigation {
    width: 100%;

    &__wrapper {
        display: flex;
    }

    &__special-menu {
        display: flex;
        gap: 8px;

        button {
            padding: 8px 16px 10px;
            border-radius: 8px;
        }
    }

    &__account-menu {
        margin-left: auto;
    }

    &__entity-label {
        display: none;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .navigation {
        height: 100%;

        &__wrapper {
            flex-direction: column;
            height: 100%;
            padding: 20px 0;
            background: #fff;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
        }

        &__special-menu {
            flex-direction: column;
            gap: 0;
            margin-bottom: 24px;

            button {
                justify-content: flex-start;
                width: 100%;
                text-align: left;
                border-radius: 0;
            }
        }

        &__entity-label {
            display: flex;
            align-items: baseline;
            padding-left: 14px;
            font-family: 'Avenir Next';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(65, 98, 131, 0.6);
            margin-bottom: 8px;
        }
    }
}

@import '@/assets/scss/global.scss';

.pagination {
    user-select: none;
    font-family: 'UniNeue';
    display: flex;
    align-items: center;

    .arrow {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #c3c7d1;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s linear;
        color: $dark;
        flex-shrink: 0;

        &:hover {
            border: solid 1px #909ca8;
        }

        &:active {
            border: solid 1px #486b8f;
        }

        &.disabled {
            border: solid 1px #d9deea;
            color: #b1bfcc;
            pointer-events: none;
        }

        span {
            display: block;
            font-size: 10px;
        }

        &.left {
            margin-right: 5px;

            span {
                transform: rotate(180deg);
            }
        }

        &.right {
            margin-left: 5px;
        }
    }

    .list {
        display: inline-flex;
        justify-content: space-between;

        .page {
            font-size: 16px;
            letter-spacing: -0.1px;
            color: #9caec5;
            cursor: pointer;
            padding-left: 6px;
            padding-right: 6px;
            text-align: center;
            display: inline-block;
            font-weight: bold;
            transition: all 0.2s linear;

            &:hover:not(.hidden) {
                color: #519cec;
                text-decoration: underline;
            }

            &.active,
            &:active {
                color: #416283 !important;
            }

            &.hidden {
                position: relative;
                color: transparent;
                user-select: none;
                cursor: auto;
                pointer-events: none;
                max-width: 22px;

                &:before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    content: '...';
                    color: #9caec5;
                    letter-spacing: -0.1px;
                }

                & + .hidden {
                    display: none;
                }
            }
        }
    }
}

@import 'styles/global.scss';
.order-data-short {
    .item {
        margin-bottom: 8px;

        .label {
            font-weight: 400;
            font-size: 14px;
        }
        .value {
            font-weight: 500;
            font-size: 14px;
        }
    }
}
.order-item-confirm {
    .order-item-confirm__wrapper.wrap {
        max-width: 800px;
        margin-top: 32px;
        border-radius: 8px;
        .item {
            font-size: 14px;
        }

        .section .header {
            padding: 0 0 8px 0;
            align-items: flex-start;

            > .header-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }

            > .header-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
        }

        .confirm-section {
            margin: 8px 0 16px 0;

            .section-header {
                font-size: 16px;
                margin-bottom: 12px;
                font-weight: 500;
                color: $dark-blue;
            }
        }
    }

    .order-confirmation_info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .order-confirmation_info-row {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 12px;

            font-family: 'UniNeue', sans-serif;
            font-size: 20px;

            > span {
                margin-right: 12px;
            }
        }
    }

    .order-confirmation_items {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .order-confirmation_item:not(:last-child) {
        margin-bottom: 32px;
    }
    .order-confirmation_item.archived .view-only-document-view__text svg path {
        fill: $grey;
    }
    .order-confirmation_item.archived .view-only-document-view__text svg rect {
        fill: $white;
    }

    .order-confirmation_upload {
        background: $light-silver;
        border: 1px solid $blue;
        border-radius: 4px;
        width: 100%;
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .order-confirmation_agreement {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        color: black;
        padding: 8px 20px 8px 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;
        background: white;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
            margin-right: 8px;
        }
    }

    .order-confirmation_finish {
        margin-top: 12px;
        justify-content: space-between;

        display: flex;

        .order-confirmation_cancel {
            min-width: 150px;
            margin-left: 12px;
        }
    }

    &__back-link {
        font-family: 'Avenir Next', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: black;
        display: flex;
        align-items: center;
        margin-left: -4px;
        margin-bottom: 12px;

        svg {
            width: 24px;
            margin-right: 4px;
        }

        svg path {
            stroke: black;
        }

        &:hover {
            color: $dark-blue;

            svg path {
                stroke: $dark-blue;
            }
        }
    }

    button {
        padding: 0 6px 0 0;
    }

    .zoho-frame {
        border: 1px solid $light-silver;
        border-radius: 4px;
        outline: none;
    }
}

.order-confirmation_status {
    background: white;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-family: 'UniNeue', sans-serif;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);

    &.waiting {
        background: white;
        color: $dark-blue;
        border-color: $dark-blue;
    }

    &.archived {
        background: $grey;
        color: white;
        border-color: transparent;
    }

    &.signing {
        background: white;
        color: $orange;
    }

    &.signed {
        background: white;
        color: $blue;
    }

    &.canceled {
        background: white;
        color: $error;
    }
}

.archived-confirmations {
    hr {
        margin-top: 16px;
        border:1px solid $border-color;
    }
    margin-left: 8px;
    h3 {
        font-weight: 500;
        color: $dark-blue;
    }
    &__item {
        display: flex;
        padding: 12px 8px;
        &:hover {
            cursor: pointer;
            background: $light-silver;
        }
    }
    &__headings {
        color: $dark-blue;
        font-weight: 500;
        margin-bottom: 4px;
        border-bottom: 1px solid $border-color;

        &:hover {
            cursor: default;
            background: white;
        }
    }
    &__header {
        font-weight: 500;
        white-space: nowrap;
        margin-right: 12px;
        min-width: 30%;
    }
    &__col {
        min-width: 30%;
    }
}

@media screen and (max-width: 500px) {
    .order-item-confirm {
        .wrap {
            margin-top: 16px;
        }

        .order-confirmation_finish {
            flex-direction: column;

            .order-confirmation_confirm {
                max-width: 100%;
            }

            .order-confirmation_cancel {
                width: 100%;
                margin-left: 0;
                margin-top: 12px;
            }
        }
    }
}

@import 'styles/global.scss';
.status-dot.hint {
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 16px;
    .status-dot-inner {
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }
}
.order-status-wrap {
    display: flex;
    width: 100%;

    .order-status {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.has-style {
            background-color: $light-silver;
            border-radius: 5px;
            padding: 4px 10px;
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);

            line-height: 16px;
            letter-spacing: -0.1px;

            font-weight: 500;

            &.pending,
            &.dropped {
                background-color: #fbf4eb;
                color: $orange;
                box-shadow: none;
            }

            &.expired {
                color: $main;
            }

            &.closed {
                color: $main;
            }

            &.success {
                background-color: #e9f8ef;
                color: #52a775;
            }

            &.reject {
                background-color: #fbebeb;
                color: #df5151;
            }

            &.done {
                background-color: #f4f4f4;
                color: #52a775;
            }

            &.common {
                background-color: #f4f4f4;
            }

            &.adjustmentPending {
                color: $orange;
            }

            &.adjustment {
                color: #52a775;
            }
        }
    }

    &.large {
        .order-status {
            line-height: 26px;
        }
    }
}

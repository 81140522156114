.empty-frame {
  background-image: url('../../../assets/images/photo-empty-frame.svg');
  background-repeat: no-repeat;
  background-color: #F7F8FA;

  &:hover .empty-frame__add {
    display: block;
  }

  &__add {
    position: absolute;
    width: 41px;
    height: 22px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #000000;
    z-index: 1;
    display: none;
    background: #F7F8FA;
  }
}

@import 'styles/global.scss';

.info-wrapper {
    position: relative;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #dbe2ef;
    font-size: 13px;
    text-align: center;
    color: $dark;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info-wrapper-container {
        text-align: center;
        width: 100%;
    }

    &.warning {
        background: #FFFFFF;
        border: 1px solid #E26300;
        border-radius: 8px;
    }
    &.pending {
        background: #FFFFFF;
        border: 1px solid rgba(1, 1, 1, 0.6);
        border-radius: 8px;
    }

    a {
        font-size: 13px;
        color: $blue;
        letter-spacing: normal;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 100%;
        font-size: 10px;
        cursor: pointer;

        &:hover span {
            color: #aeb6bf;
        }

        &:active span {
            color: #757e88;
        }

        span {
            color: #b5bdcd;
        }
    }
}

@import 'styles/global.scss';

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
  min-height: 600px;
  .login-form {
    max-width: 600px;
    display: block;
    min-width: 380px;
    padding: 24px;
    background: white;
    @media screen and (max-width: $media-limit-mobile-sm) {
      min-width: calc(100% - 32px);
    }
  }
}

@import '@/assets/scss/global.scss';

.cards {
    display: flex;

    .control {
        width: 280px;
        margin-right: 20px;
    }

    button {
        height: 40px;
        line-height: 38px;
        &.topMargin {
            margin-top: 22px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .cards {
        display: flex;
        padding-bottom: 0;
        flex-wrap: wrap;

        .control {
            width: auto;
            flex-grow: 1;
            margin-right: 0;
            margin-bottom: 10px;
        }

        button {
            height: 42px;
            line-height: 42px;
        }
    }
}

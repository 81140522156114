.cell-button {
    justify-content: flex-end;

    button {
        margin-bottom: 10px !important;
    }
}

.entity-row-btn {
    min-width: 70px;
}

.table-line-modal-entities {
    .cell-id {
        min-width: 50px;
        position: relative;
    }

    .order-details {
        display: flex;
        flex-direction: row;

        .order-detail {
            text-align: center;
            padding: 0 10px;
        }

        .alert {
            color: #df5151;
            font-weight: 600;
        }

        .order-details-pipe {
            border: 1px solid #dbe2ef;
        }

        .order-label {
            display: flex;
            font-style: italic;
        }
    }
}

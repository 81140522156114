.cell-button {
    justify-content: flex-end;
    button {
        margin-left: 10px;
    }
}

.table-line-modal-manager {
    .cell {
        &-email {
            min-width: 220px;
        }

        &-first-name {
            min-width: 100px;
        }

        &-last-name {
            min-width: 150px;
        }

        &-phone {
            min-width: 140px;
        }

        &-type {
            min-width: 100px;
        }
    }
}

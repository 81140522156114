@import '@/assets/scss/global.scss';

.company-settings {
    .form-wrap {
        display: flex;
        flex-wrap: wrap;

        .field {
            width: calc((100% - 80px) / 3);
            margin-right: 40px;
            flex-shrink: 0;

            .input-text + .checkbox {
                margin-bottom: 20px;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            .receive-sms {
                margin: 8px 0;
            }
        }
    }

    .addresses {
        .field {
            display: flex;

            .input-location {
                flex-shrink: 0;
                width: calc((2 * (100% - 80px) / 3) + 40px);
                margin-right: 40px;
            }

            .checkbox {
                padding-top: 30px;
            }
        }
    }

    .cards {
        padding-bottom: 30px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .company-settings {
        padding: 0 10px;

        .form-wrap {
            .field {
                width: 100%;
                margin-right: 0;

                &.first-name,
                &.last-name {
                    width: calc(50% - 10px);
                }

                &.last-name {
                    margin-left: 20px;
                }
            }
        }

        .links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px 0;

            .link {
                margin-bottom: 20px;
            }
        }

        .addresses {
            .field {
                display: block;

                &.billing-address,
                &.legal-address {
                }

                .input-location {
                    width: auto;
                    margin-right: 0;
                }

                .checkbox {
                    padding: 0;
                    margin-bottom: 30px;
                }
            }
        }
    }
}

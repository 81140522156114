.not-found {
    background: #484e55;
    height: calc(100vh - 190px);

    & > div {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .wrap {
        flex-shrink: 0;
        width: 300px;

        .title {
            font-family: 'UniNeue';
            font-size: 59px;
            font-weight: bold;
            letter-spacing: -0.5px;
            color: #fff;
        }

        .description {
            padding-bottom: 70px;
            font-family: 'UniNeue';
            font-size: 20px;
            letter-spacing: -0.2px;
            color: #fff;
        }
    }
}

.page {
    display: flex;

    & > div {
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    .tabs {
        display: flex;

        .tab {
            height: 48px;
            padding: 0 30px;
            background: transparent;
            line-height: 48px;
            font-family: 'UniNeue';
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.2px;
            color: #9caec5;
            transition: color 0.2s linear;

            &:disabled {
                color: #d3dae2;
            }

            &:hover {
                color: #587da2;
            }

            &:active {
                color: $dark-blue;
            }

            &.active {
                color: $dark-blue !important;
                background: #fff;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }
    }

    .wrap {
        background-color: #fff;
        padding: 30px;
        border-radius: 4px;

        .section {
            .header {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 0 0 25px 0;

                .title {
                    height: auto;
                    font-family: 'UniNeue';
                    font-size: 24px;
                    font-weight: bold;
                    color: $dark-blue;

                    .subtitle {
                        font-weight: 500;
                        letter-spacing: -0.1px;
                        color: $dark-old;
                        font-size: 14px;
                        font-family: 'AvenirNext';
                        margin-left: 30px;
                    }
                }

                .subtitle {
                    font-weight: 500;
                    letter-spacing: -0.1px;
                    color: $dark-old;
                    font-size: 14px;
                    font-family: 'AvenirNext';
                }
            }
        }

        .page-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btns {
                display: flex;
                align-items: center;

                button {
                    margin-right: 20px;
                }
            }
        }
    }
}

@import 'styles/global.scss';

.modal-book-request {
    .tabs {
        margin-bottom: 30px;
    }

    div.checkbox {
        align-items: flex-start;
        .control {
            margin-top: 2px;
        }
        .text .label {
            padding-top: 0;
            white-space: normal;
            font-size: 14px;
            color: $main;
        }
    }
    a {
        color: $blue;
        font-weight: 500;
    }
    .receiveSms {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: -8px;
        margin-bottom: 8px;
        margin-left: 0;
    }
    .error {
        color: #FF5C6F;
    }
    .info {
        color: #EE8300;
        margin-top: 15px;
        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .link {
        cursor: pointer;
    }
    .btns {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}

@import '@/assets/scss/global.scss';

.slider-input {
    .label {
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.1px;
        color: $dark;
        text-transform: uppercase;
        padding-bottom: 15px;
        text-align: left;
    }

    &__label {
        font-size: 13px;
        color: #3498db;
        white-space: nowrap;
        margin-top: 32px;
    }

    div {
        div {
            transition: box-shadow 0.4s ease-in-out;

            &:focus {
                box-shadow: 1px 1px 8px 1px #519cec !important;
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.upload-button {
    &__btn {
        background: $light-silver;
        border: 1px solid $blue;
        border-radius: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 22px;

        svg {
            margin-right: 8px;
        }

        .loader {
            position: static;
            width: auto;
            margin-right: 8px;
        }
    }
}

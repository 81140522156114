@import 'styles/global.scss';

.selector-with-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 8px 0 12px 0;

  .loader {
    justify-content: flex-start;
  }

  .select-item {
    padding-bottom: 12px;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $silver;
    }

    .select-item__control {
      display: flex;
      align-items: center;

      .select-item__title {
        margin-left: 8px;
        font-weight: 500;
        color: black;
        font-size: 14px;
        user-select: none;
        flex: 1;
      }

      .select-item__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        border: 2px solid $grey;
        width: 18px;
        height: 18px;
      }

      .select-item__circle-inner {
        display: none;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $blue;
      }

      &:hover:not(.active) {
        opacity: 0.7;
        transition: 0.2s opacity ease-in-out;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active {
        .select-item__title {
          color: $blue;
        }

        .select-item__circle {

          border: 2px solid $blue;
        }

        .select-item__circle-inner {
          display: block;
        }

      }
    }
  }
  .select-item__body {
    padding-top: 12px;

  }
}

@import 'styles/global.scss';

html {
    width: 100vw;
    overflow-x: hidden;

    &.has-modal {
        overflow: hidden;
    }
}

html,
body {
    font-family: 'AvenirNext', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $main;
    background: #fff;
    font-stretch: normal;
    min-height: 100%;
    min-width: 1020px;
    height: 100%;
    direction: ltr;
    text-align: left;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
}

textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='submit'],
input[type='password'],
input[type='email'],
.input-checkbox {
    -webkit-appearance: none;
    border-radius: 0;
    font-family: 'SFUIText', sans-serif;
}

input::-webkit-contacts-auto-fill-button {
    position: absolute;
    right: 0;
    display: none !important;
    visibility: hidden;
    pointer-events: none;
}

input:focus::-webkit-textfield-decoration-container {
    visibility: hidden;
    pointer-events: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #f7f8fa !important;
    -webkit-text-fill-color: $dark !important;
    color: $dark !important;
}

* {
    -webkit-tap-highlight-color: transparent;
}

*::-ms-clear {
    display: none;
}

* {
    outline: none !important;
    box-sizing: border-box;
}

img {
    display: block;
}

a {
    text-decoration: none;
    cursor: pointer;
}

button {
    position: relative;
    width: auto;
    padding: 0 20px;
    margin: 0;
    font-family: 'UniNeue', sans-serif;
    overflow: hidden;
    background: transparent;
    border: none;
}

.wrapper {
    position: relative;
    padding: 0 10px;

    & > div {
        width: 1000px;
        margin: 0 auto;
    }
}

.media-mobile {
    display: none;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .env {
        color: $error;
        z-index: 1;
        width: 100%;
        font-weight: 600;
        padding: 12px 24px;
        font-size: 14px;
        text-align: center;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
    }

    & > .container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        overflow: hidden;

        .content {
            background: $silver;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            padding: 0 0 0 0;
            > .loader {
                margin: 24px auto 12px auto;
                flex: 1;
            }

            @media screen and (max-width: $media-limit-mobile-sm) {
                > .loader {
                    margin: 48px auto;
                }
            }

            & > *:not(.loader) {
                flex: 1 0 auto;
            }
        }
    }
}

.link {
    font-family: 'UniNeue';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: $dark-blue;
    transition: color 0.2s linear;

    &:hover {
        color: #557ca2;
    }

    &:active {
        color: #254566;
    }
}

.secondary-link {
    font-family: 'UniNeue';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: #979ea6;
    border-bottom: 1px solid #979ea6;
    transition: color 0.2s linear;

    &:hover {
        color: #aeb6bf;
    }

    &:active {
        color: #757e88;
    }
}

.page-title {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: $dark-blue;
    font-family: 'UniNeue';
    padding: 35px 0 15px 0;
}

.tooltip {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 20;
    padding: 5px 10px;
    font-size: 12px;
    letter-spacing: -0.1px;
    color: $dark;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border: solid 1px $border-color;
    border-radius: 3px;
    transition: opacity 0.2s linear;
    opacity: 0;
    visibility: hidden;
    font-family: 'AvenirNext';

    .arrow {
        position: absolute;
        top: -5px;
        left: 20px;
        width: 10px;
        height: 5px;
        overflow: hidden;

        &:before {
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            width: 6px;
            height: 6px;
            content: '';
            border: 1px solid #e5e9f1;
            transform: rotate(45deg);
            background: #fff;
        }
    }
}

.ps.scrollbar {
    &:hover,
    & {
        & > .ps__scrollbar-y-rail {
            width: 14px !important;
            background: #fff !important;
            opacity: 1 !important;

            &:before {
                position: absolute;
                top: 10px;
                left: 5px;
                bottom: 10px;
                display: block;
                width: 4px;
                content: '';
                background: #f7f8fa;
            }

            &:hover {
                background-color: transparent;

                & > .ps__scrollbar-y {
                    width: 4px;
                    background: #979ea6;
                }
            }

            & > .ps__scrollbar-y {
                right: 0 !important;
                width: 14px !important;
                background: transparent !important;
                border-radius: 0 !important;

                &:before {
                    position: absolute;
                    top: 10px;
                    left: 5px;
                    right: 5px;
                    bottom: 10px;
                    display: block;
                    width: 4px;
                    content: '';
                    border-radius: 0;
                    background-color: #979ea6 !important;
                }
            }
        }

        &.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail {
            background: transparent;
        }
    }

    &.ps--active-y {
        padding-right: 14px;
    }
}

.stripe-element.StripeElement {
    width: 100%;
    height: 40px;
    padding: 7px 10px;
    font-family: 'AvenirNext';
    font-size: 15px;
    border-radius: 3px;
    background-color: #f7f8fa;
    border: solid 1px $border-color;
    line-height: 24px;
    color: $dark;

    &--focus {
        border: solid 1px #519cec;
        color: #252729;
    }

    &--invalid {
        border-color: $error;
    }
}

.checkbox-agreement {
    font-size: 13px;
    line-height: 1.31;
    color: $dark-old;

    a {
        font-size: 13px;
        line-height: 1.31;
        color: $dark-old;
        border-bottom: 1px solid $dark-old;

        &:hover {
            color: $dark;
            transition: color 0.2s linear;
        }
    }
}

.gg-close-o {
    box-sizing: border-box;
    position: relative;
    display: flex;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-radius: 40px;
}

.gg-close-o::after,
.gg-close-o::before {
    content: '';
    display: flex;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 6px;
    left: 2px;
}

.gg-close-o::after {
    transform: rotate(-45deg);
}

@import 'styles/global.scss';

.warehouse-settings-company {
    &.page .wrap {
        border-top-left-radius: 0;
    }

    .links.help-links a {
        font-size: 12px;
    }

    .form-wrap {
        display: flex;
        flex-wrap: wrap;

        .field {
            width: calc((100% - 80px) / 3);
            margin-right: 40px;
            flex-shrink: 0;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .receive-sms {
                margin: 8px 0;
            }
        }
    }

    hr {
        margin: 15px 0;
        border: 0;
        border-top: 1px solid #e5e9f1;
    }

    .billing-address {
        width: 100%;
        flex-grow: 0.5;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .warehouse-settings-company {
        .form-wrap {
            display: flex;
            flex-wrap: wrap;

            .field {
                width: 100%;
                margin-right: 0;

                &.first-name,
                &.last-name {
                    width: calc(50% - 10px);
                }

                &.last-name {
                    margin-left: 20px;
                }
            }
        }

        .links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px 0;

            .link {
                margin-bottom: 20px;
            }
        }
    }
}

.company-index {
    .entity-name {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        button,
        h2 {
            display: inline-flex;
        }
    }
}

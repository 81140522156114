@import '@/assets/scss/global.scss';

$class-name: 'entity-status';

.#{$class-name} {
    padding: 2px 4px 2px;

    border: 1px solid $entity-inactive;
    border-radius: 4px;
    color: $entity-inactive;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    height: 18px;
    text-transform: none;
    margin-right: 8px;
    white-space: nowrap;

    &--active {
        color: $entity-active;
        border-color: $entity-active;
    }

    &--banned {
        color: $entity-banned;
        border-color: $entity-banned;
    }

    &--rejected {
        color: $entity-rejected;
        border-color: $entity-rejected;
    }

    &--dotted {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        border: none;
        padding: 0;

        &:before {
            display: block;
            width: 6px;
            height: 6px;
            content: '';
            border-radius: 50%;
            background-color: $entity-inactive;
            box-shadow: 0 0 4px 0 $entity-inactive;
        }

        &.#{$class-name}--banned:before {
            background-color: $entity-banned;
            box-shadow: 0 0 4px 0 $entity-banned;
        }

        &.#{$class-name}--rejected:before {
            background-color: $entity-rejected;
            box-shadow: 0 0 4px 0 $entity-rejected;
        }

        &.#{$class-name}--active:before {
            background-color: $entity-active;
            box-shadow: 0 0 4px 0 $entity-active;
        }
    }
}

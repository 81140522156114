@import 'styles/global.scss';
.olimp-sign-up {

  .sign-up_info-text {
    text-align: center;
    width: 100%;
    display: block;
    font-family: 'Avenir Next';
    font-style: normal;
    margin-top: 20px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }
  .sign-up_description small {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}
.icon {
    &.type-services {
        color: #9caec5;
        background-repeat: no-repeat;
    }

    &.type-cards,
    &.type-common {
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.type-cards {
        width: 42px;
        height: 24px;
    }

    &.type-common {
        display: block;
        width: 20px;
        height: 20px;
        min-width: 20px;
    }

    &.icon-visa {
        background-image: url('../../assets/images/ic-card-visa.svg');
    }

    &.icon-mastercard {
        background-image: url('../../assets/images/ic-card-mastercard.svg');
    }

    &.icon-amex {
        background-image: url('../../assets/images/ic-card-amex.svg');
    }

    &.icon-unknown {
        background-image: url('../../assets/images/ic-card-default.svg');
    }

    &.icon-discover {
        background-image: url('../../assets/images/ic-card-discover.svg');
    }

    &.icon-diners {
        background-image: url('../../assets/images/ic-card-dinersclub.svg');
    }

    &.icon-jcb {
        background-image: url('../../assets/images/ic-card-jcb.svg');
    }

    &.icon-unionpay {
        background-image: url('../../assets/images/ic-card-unionpay.svg');
    }

    &.icon-refresh {
        background-image: url('../../assets/images/icon-refresh.png');
    }

    &.icon-warehouse {
        background-image: url('../../assets/images/icon-warehouse.svg');
    }

    &.icon-carrier {
        background-image: url('../../assets/images/icon-carrier.svg');
    }

    &.icon-warehousing {
        background-image: url('../../assets/images/icon-warehouse.svg');
        display: block;
        width: 20px;
        height: 20px;
    }
}

@import '@/assets/scss/global.scss';

.entities-menu {
    width: 240px;
    max-width: 240px;
    min-width: 240px;

    &__entity {
        padding: 0;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
            margin-right: 6px;
        }
    }

    &__entity-title {
        text-align: left;
    }

    &__btn {
        color: $dark-blue;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding: 10px 14px;
        width: 100%;

        &:hover {
            background: $silver;
        }
    }

    .dropdown-menu__list {
        padding: 0;
        max-height: 240px;
        overflow-y: auto;
    }

    .loader .spinner {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .entities-menu {
        min-width: 186px;
    }
}

@import '@/assets/scss/global.scss';

.account-menu {
    max-width: 200px;
    min-width: 148px;

    .dropdown-menu__label {
        justify-content: flex-end;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .account-menu {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 100%;

        .dropdown {
            display: flex;
            flex-direction: column;
            height: 100%;

            .list {
                position: static;
                flex-grow: 1;
            }
        }

        .dropdown-menu__label {
            font-family: 'Avenir Next';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            text-transform: uppercase;
            justify-content: flex-start;
            align-items: baseline;
            color: rgba(65, 98, 131, 0.6);
        }

        .dropdown-menu__toggle {
            height: auto;
            pointer-events: none;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .icon-arrow {
                display: none;
            }
        }

        .dropdown-menu__list {
            visibility: visible;
            opacity: 1;
            box-shadow: none;
            height: 100%;
        }

        .dropdown-menu__item {
            font-family: 'UniNeue', sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;

            &:last-child {
                border: none;
            }
        }
    }
}

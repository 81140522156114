@import '@/assets/scss/global.scss';

.form-search {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 7px 33px 0 rgba(0, 0, 0, 0.15);
    padding: 30px;

    .delivery {
        align-items: flex-end;

        .type-delivery {
            flex-grow: 10;
            margin-right: 40px;
        }

        .delivery-count,
        .extra-stops {
            margin-bottom: 0;
        }

        .delivery-count {
            flex-grow: 1;
        }

        .extra-stops {
            flex-grow: 3;
        }
    }

    .pickup {
        align-items: center;
    }

    .field {
        width: 140px;
        margin-right: 40px;
        flex-shrink: 0;

        &:last-child {
            margin-right: 0;
        }

        &.address {
            width: auto;
            flex-grow: 1;
        }

        &.days {
            flex-grow: 1;
            margin-bottom: 0;
            .input-text .input-wrapper {
                margin-bottom: 0;
            }
        }

        &.amount {
            position: relative;
            width: 169px;
            margin-right: 40px;
            .input-text + .tabs {
                position: absolute;
                width: 100%;
            }
        }
    }

    .slider-input {
        width: 200px;
        flex-grow: 0;
    }

    .form-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .radio.wrap {
        margin-bottom: 10px;
    }

    .checkboxes {
        > label {
            margin-top: 8px;
            display: flex;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: -0.1px;
            color: #000;
            text-transform: uppercase;
            text-align: left;
        }

        .checkbox {
            vertical-align: top;
            display: inline-flex;
            width: 50%;
        }
    }

    .form-group {
        display: flex;

        &.row + .row {
            margin-top: 10px;
        }

        .start_date {
            margin-right: 40px;
        }

        .icon-wrapper {
            display: flex;
            justify-content: center;
            transform: rotateX(180deg);
            align-items: center;
            width: 40px;
            height: 40px;
        }

        .column {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
                padding-top: 20px;

                button {
                    min-width: 140px;
                }
            }

            &.button {
                display: flex;
                align-self: flex-end;
                flex-grow: 2;
                text-align: -webkit-right;
                text-align: right;
                justify-content: flex-end;

                button {
                    display: inline-flex;
                }

                .attention {
                    font-size: 15px;
                    color: $black;
                    text-align: right;
                    &__link {
                        color: $blue;
                        &:hover {
                            color: $blue-pressed;
                        }
                    }
                }
            }

            &.services {
                flex-grow: 4;
                margin-right: 0;

                .checkbox {
                    margin: 8px 0 4px 0;
                }

                .checkboxes {
                    padding-top: 10px;

                    &.margin1 {
                        margin-left: 0;
                    }

                    &.margin2 {
                        margin-left: 156px;
                    }

                    &.margin3 {
                        margin-left: 0;
                    }
                }

                .service {
                    flex-grow: 10;
                    margin-right: 40px;
                }
            }

            &.slider {
                flex-basis: 120px;
            }

        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .form-search {
        padding: 20px 15px;

        .form-wrap {
            display: flex;
            flex-wrap: wrap;

            .column {
                width: 100%;
                margin-bottom: 16px;
                margin-top: 8px;
            }
        }
        .delivery {
            align-items: flex-start;
        }

        .field {
            width: 100%;
            margin-right: 0;
            flex-shrink: 0;

            &:last-child {
                margin-right: 0;
            }

            &.amount {
                margin-right: 20px;
                width: calc(50% - 10px);
            }

            &.days {
                margin-bottom: 20px;
                margin-top: 20px;
                width: 100%;
            }

            &.address {
                width: 100%;
                flex-grow: 1;
            }
        }

        .form-group {
            display: flex;
            flex-direction: column;

            .checkbox {
                margin-bottom: 8px;
            }

            .column {
                margin-right: 0;

                &:last-child {
                    margin-right: 0;
                    padding-top: 40px;

                    button {
                        min-width: auto;
                        width: 100%;
                    }
                }

                &.services {
                    flex-grow: 1;
                    margin-top: 20px;

                    .service {
                        margin-right: 0;
                    }

                    .checkboxes {
                        padding-top: 0;

                        label {
                            margin: 16px 0;
                        }

                        .checkbox {
                            margin: 6px 0;
                        }

                        &.margin2 {
                            margin-left: 136px;
                        }
                    }
                }

                &.slider {
                    flex-basis: 0;
                    padding-top: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .form-search {
        .form-group {
            .column {
                &.services {
                    .checkboxes {
                        &.margin2 {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

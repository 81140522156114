.static {
    padding: 40px 0;
    margin: 0 auto;
    font-size: 14px;

    h3 {
        font-size: 16px;
        font-weight: 600;
    }

    h4 {
        font-size: 14px;
        font-weight: 600;
    }

    strong {
        font-weight: 600;
    }

    .hide-type {
        list-style-type: none;

        & > li:before {
            display: none;
        }
    }

    span {
        &.normal {
            font-weight: normal;
        }
    }

    a {
        font-size: 13px;
        line-height: 1.31;
        color: #51585f;
        border-bottom: 1px solid #51585f;
    }

    ul {
        list-style-type: disc;
        margin: 10px 0;
        padding-left: 30px;

        li {
            margin-bottom: 10px;
        }
    }

    & > ol {
        counter-reset: li;

        & > li {
            position: relative;
            margin-bottom: 20px;
            padding-left: 40px;

            &:before {
                counter-increment: li;
                content: counters(li, '.') '. ';
                margin-right: 5px;
                position: absolute;
                top: 0;
                left: 0;
            }

            & > ol {
                counter-reset: li;
                margin-top: 10px;

                & > li {
                    position: relative;
                    margin-bottom: 10px;
                    padding-left: 40px;
                    margin-left: -40px;

                    &:before {
                        counter-increment: li;
                        content: counters(li, '.') '. ';
                        margin-right: 5px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    & > ol {
                        margin-top: 10px;
                        counter-reset: li;
                        list-style-type: lower-latin;
                        margin-left: 30px;

                        & > li {
                            position: relative;
                            margin-bottom: 10px;
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

@import 'styles/global.scss';

.select {
    &.select-time {
        .current > .text-wrapper > .text > .name {
            min-width: 40px;
        }
    }
    .current {
        .label {
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: -0.15px;
            color: $dark;
            text-transform: uppercase;
            white-space: pre;
            padding-bottom: 7px;
            min-height: 21px;
        }

        .text-wrapper {
            color: $dark;
            position: relative;
            cursor: pointer;

            .text {
                display: flex;
                align-items: center;
                border: 1px solid $border-color;
                font-size: 15px;
                background-color: #f7f8fa;
                width: 100%;
                padding: 7px 40px 7px 10px;
                line-height: 24px;
                min-height: 40px;
                border-radius: 4px;

                &.has-error {
                    border-color: $error;
                }

                .name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.placeholder {
                    color: #979ea6;
                }
            }

            .icon-arrow {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                font-size: 5px;
            }
        }
    }

    &.opened {
        .current {
            .text-wrapper .text {
                background-color: #fff;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .icon-arrow {
                transform: rotate(180deg);
            }
        }
    }

    .options {
        position: relative;
        top: -1px;
        background: #fff;
        width: 100%;
        font-size: 15px;
        box-shadow: 4px 6px 24px rgba(65, 98, 131, 0.25);
        border-radius: 8px;

        .scrollbar {
            height: 100%;
        }

        .s-content {
            padding: 8px 0;
        }

        .option {
            position: relative;
            display: flex;
            align-items: center;
            padding: 12px 15px;
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            &:hover,
            &.active {
                background-color: $silver;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-grow: 1;
            }
        }
    }

    &.white {
        .text-wrapper {
            .text {
                background-color: $white;
            }
        }
    }

    &.small {
        .text-wrapper {
            .text {
                padding: 4px 40px 4px 10px;
            }

            .icon {
                height: 33px;
            }
        }
    }

    .delete {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #ed6868;
        transition: color 0.2s linear;
        width: 25px;
        height: 25px;
        flex-shrink: 0;

        &:hover {
            color: #ff4242;
        }

        &:active {
            color: #df4d4d;
        }
    }

    .sort {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #ed6868;
        transition: color 0.2s linear;
        width: 25px;
        height: 25px;
        flex-shrink: 0;

        &:hover {
            color: #ff4242;
        }

        &:active {
            color: #df4d4d;
        }
    }

    .icon {
        margin-right: 10px;
        flex-shrink: 0;
    }
}

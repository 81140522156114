@import 'styles/global.scss';

.service-group {
    font-weight: 500;
    font-size: 12px;

    &:not(:first-child) {
        margin-top: 8px;
    }

    .main .title {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    .sub {
        margin-top: 16px;

        > .title {
            margin-bottom: 8px;
        }

        .title {
            width: 100%;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);

            span {
                font-size: 10px;
                font-weight: 600;
                color: black;
                position: absolute;
                right: 0;
                cursor: pointer;
            }
        }

        .label {

        }
    }
}

@import 'styles/global.scss';

.warehouses-list {
    position: relative;
    display: flex;
    justify-content: center;

    & > div {
        padding-bottom: 50px;
    }

    .form-search {
        box-shadow: none;
        margin-bottom: 25px;
    }

    .hint.status-dot > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        .status-dot-inner {
            margin-right: 12px;
        }
    }

    .modes {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 0 10px 0;

        .list-title {
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            color: $grey;
            margin-right: 8px;
        }
        .list-select {
            margin-right: 12px;
        }

        .count {
            font-size: 14px;
            letter-spacing: -0.1px;
            color: #979ea6;
        }

        .list {
            display: flex;

            .checkbox {
                align-items: center;
                margin-right: 20px;

                .label {
                    font-size: 12px;
                }
            }

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 40px;
                background: #fff;
                transition: all 0.2s linear;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                &:hover {
                    background-color: #fafafa;
                }

                &:active {
                    background-color: #d7dce6;
                    box-shadow: inset 0 0 5px 0 rgba(43, 46, 73, 0.25);
                }

                &.active {
                    background-color: #e6e9f0 !important;
                    box-shadow: inset 0 0 2px 0 rgba(43, 46, 73, 0.25);
                }

                .icon {
                    color: $dark;

                    &-pin {
                        font-size: 18px;
                    }

                    &-sandwich {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.permissions-entity {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0 20px;

    & > div {
        background: #b1bfcc;
        border-radius: 3px;
        padding: 4px;
        margin-right: 8px;
        cursor: pointer;
        font-size: 14px;

        &.active {
            background: #2f7aca;
            color: #fff;
        }
    }
}

@import 'styles/global.scss';
.map-wrapper {
    height: 540px;
    width: 100%;

    .si-frame {
    }

    .si-float-wrapper {
        position: absolute;
        width: 230px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        transform: translate3d(17px, -50%, 0);

        // &:before {
        //     position: absolute;
        //     top: 36px;
        //     left: -4px;
        //     content: '';
        //     display: block;
        //     width: 10px;
        //     height: 10px;
        //     background-color: #fff;
        //     transform: rotate(45deg);
        // }
    }

    .si-content {
        padding: 14px;
    }

    .si-close-button {
        display: none;
        // position: absolute;
        // right: 0;
        // width: 30px;
        // height: 30px;
        // font-size: 20px;
        // color: #979ea6;
        // transition: color 0.2s linear;

        // &:hover {
        //     color: #aeb6bf;
        // }

        // &:active {
        //     color: #757e88;
        // }
    }

    .popups {
        display: none;
    }

    .si-content {
        font-family: 'AvenirNext';
        cursor: default;

        .label {
            font-size: 13px;
            padding-bottom: 3px;
            color: $grey;
        }

        .value {
            font-size: 15px;
            color: $dark;
            padding-bottom: 10px;
        }
    }

    .photos-wrapper {
        width: 230px;
        height: 172px;
        margin-left: -14px;
        margin-right: -14px;
        margin-top: -14px;
        margin-bottom: 10px;

        .photos-view__preview, .photos-view__slide-inner {
            height: 172px;
        }
    }

    .item-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .currency {
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
    }
}

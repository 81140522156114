@import 'styles/global.scss';

.view-only-document-view {
    position: relative;
    background-color: $light-silver;
    width: 100%;
    padding: 16px 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: $black;

    &:hover {
        cursor: pointer;
        background-color: #fcfcfc;
    }

    &.disabled {
        pointer-events: none;
        background-color: $silver;
        color: $grey;
    }

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    &__text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        font-weight: 500;
        font-size: 14px;

        white-space: nowrap;

        svg {
            margin-right: 8px;
        }
    }

    &__open,
    .loader {
        margin-left: 8px;
    }

    &__open {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 102px;
        color: $blue;

        svg {
            margin-right: 8px;

            path {
                stroke: $blue;
            }
        }
    }

    .loader {
        position: static;
        width: 24px;
    }
}

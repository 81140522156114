@import 'styles/global.scss';

.textarea {
    .label {
        font-family: AvenirNext;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: -0.1px;
        color: $dark;
        text-transform: uppercase;
        padding-bottom: 7px;
    }

    textarea {
        width: 100%;
        height: 65px;
        padding: 7px 10px;
        font-family: 'AvenirNext';
        font-size: 15px;
        border-radius: 3px;
        background-color: #f7f8fa;
        border: solid 1px $border-color;
        line-height: 24px;
        color: $dark;
        resize: none;

        @include placeholder() {
            color: #979ea6;
        }
    }

    &.focused {
        textarea {
            border: solid 1px #519cec;
            color: #252729;
        }
    }

    &.disabled {
        pointer-events: none;

        .label {
            color: #9ea0a2;
        }

        textarea {
            background-color: #fbfbfc;
            border: solid 1px #f7f8fa;
            color: #bdc3cb;
        }
    }

    &.has-error {
        textarea {
            border-color: $error;
        }
    }

    &.location,
    &.has-status {
        input {
            padding-right: 40px;
        }
    }
}

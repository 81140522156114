@import '@/assets/scss/global.scss';

 
 .footer {
    max-width: 1000px;
    margin: 160px auto 60px;
    width: 100%;
    padding: 0 20px;
    color: #000 !important;
    a {
        display: inline-block;
        color: #000;
        text-decoration: none;
        outline: none;
        appearance: none;
        transition: all 0.5s ease;
    }
    &__columns {
        display: flex;
        justify-content: space-between;
    }
    &__support {
        margin-top: 56px;
    }
    &__title {
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.6;
        line-height: 1.8em;
        margin-bottom: 16px;
        margin-top: 0;
    }
    &__item {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.8em;
        opacity: 0.6;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        &:hover{
            opacity: 1;
        }
        &_terms {
            font-size: 14px;
            font-weight: 400;
            margin: 100px 0 60px;
            &-copyright,
            &-link {
                opacity: 0.6;
            }
            &-link {
                &:hover {
                    opacity: 1;
                }
            }

        }
    }
    &__social-item {
        margin-right: 12px;
        line-height: 0;
        &:hover {
            transform: rotate(360deg);
            transition: all 1.5s ease;
        }
    }
    &__social-links {
        display: flex;
    }
    &__social-link-img {
        width: 25px;
        height: 25px;
    }
    &__apps {
        display: flex;
        margin-top: 24px;
    }
    &__apps-item {
        line-height: 0;
        &:not(:last-child) {
            margin-right: 12px;
        }
    }
    &__links-img {
        height: 32px;
        border: 1px solid #000;
        border-radius: 6px;
    }
 }

@media (max-width: 768px) {
    .footer {
        width: 540px;
        margin-top: 42px;
        &__columns {
            flex-wrap: wrap;
        }
        &__column {
            &:last-child {
                margin-top: 56px;
            }
        }
        &__item {
            &_terms {
                margin-top: 56px;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 576px) {
    .footer {
        width: 100%;
        &__columns {
            flex-direction: column;
        }
        &__column {
            &:nth-child(2) {
                margin-top: 56px;
            }
        }
    }
}

@import 'styles/global.scss';

.sign_up-choose {
  display: flex;
  align-items: center;
  flex-direction: column;
  >:first-child {
    margin-bottom: 12px;
  }
}

.sign_up-select-entity {
  border: 1px solid $border-color;
  border-radius: 8px;
  font-family: 'AvenirNext';
  line-height: 20px;
  justify-content: space-between;
  text-align: left;
  font-weight: 600;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 4px;

  width: 272px;
  height: 56px;

  background: #F7F8FA;

  svg path {
    fill: black;
  }
  &:hover, &.active {

    border: 1px solid #519CEC;
    cursor: pointer;
  }
}
@import 'styles/global.scss';

.warehouse-order-item {
    &.wrapper {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        padding-top: 32px;
        padding-bottom: 63px;
    }

    &__wrapper.wrap {
        max-width: 620px;
        border-radius: 8px !important;

        &:not(:first-child) {
            margin-top: 36px;
        }
    }

    .section {
        padding-top: 40px;

        &:first-child {
            padding-top: 0;
        }
    }

    &__header {
        padding: 0 !important;
    }

    &__header-wrapper {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    &__status {
        margin-top: 12px;
        width: auto;
    }

    .timeleft {
        text-align: right;

        .timeleft-title {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.4px;
            color: $dark-blue;
            margin-bottom: 5px;
        }

        .description {
            font-size: 13px;
            letter-spacing: -0.3px;
            text-align: right;
            color: #979ea6;
        }
    }

    .form-change-status {
        flex-direction: column;
        align-items: flex-start;

        .main-line {
            display: flex;
            align-items: center;

            .select {
                width: 220px;
                flex-shrink: 0;
            }

            .order-status-wrap {
                width: auto;
            }

            .tip {
                align-items: center;
                padding: 2px 0 2px 20px;
                font-size: 14px;

                .hint {
                    display: inline-flex;
                    margin-left: 5px;
                }
            }
        }

        .btns {
            display: flex;
            margin-top: 24px;

            button {
                margin-right: 10px;
                line-height: 38px;
                height: 48px;
                min-width: 90px;
                flex-grow: 1;
            }
        }
    }

    .group {
        padding-top: 24px;

        &:first-child {
            padding-top: 0;
        }

        .item {
            display: flex;
            margin-top: 24px;

            &:first-child {
                margin-top: 0;
            }

            .label {
                color: rgba(0, 0, 0, 0.6);
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                width: 160px;
                flex-shrink: 0;
            }

            .value {
                color: $dark;
                font-weight: 500;
                flex-grow: 1;
                max-width: 530px;
                align-items: flex-start;
            }
        }
    }

    .comment {
        font-style: italic;
        word-break: break-word;
    }

    .services {
        .services-info {
            flex-grow: 1;
        }
    }

    .carrier-info {
        .value {
            word-break: break-word;
        }
    }

    .service-list {
        .item {
            flex-direction: column;
            margin-top: 16px;

            .info-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .location {
                display: flex;
                padding: 10px 0;

                .location-link {
                    width: auto;
                }

                .distance {
                    margin-left: 15px;
                    color: $grey;
                    white-space: nowrap;
                }
            }

            .info {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 8px;
                    flex-shrink: 0;
                    color: #9caec5;
                }

                .title {
                    margin-left: 4px;
                }

                .comment {
                    margin-top: 8px;
                }
            }

            .value {
                flex-grow: 0;
            }
        }
    }

    .service-details {
        display: block !important;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        flex-grow: 1;

        .service-params {
            display: flex;
        }

        .item {
            flex-direction: column;
            margin-top: 0;
            margin-left: 40px;

            &:first-child {
                margin-left: 0;
            }

            .label {
                padding-bottom: 5px;
            }

            .days-count {
                font-size: 12px;
                letter-spacing: 0.5px;
                color: $dark;
                padding-top: 5px;
                font-weight: 500;
            }
        }
    }

    .photos-wrapper {
        max-width: 400px;
    }

    .prices {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .item {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            &.total {
                font-family: 'UniNeue', 'Arial', sans-serif;
                padding-top: 12px;
                border-top: 1px solid $border-color;
                border-radius: 3px;
                color: $dark;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;

                span:first-child {
                    color: $dark-blue;
                }
            }
        }
    }

    .services-info {
        position: relative;
    }

    .details-button {
        position: absolute;
        right: 0;
        top: 0;
    }

    hr {
        margin-top: 15px;
        margin-bottom: 0;
        border: 0;
        border-top: 1px solid #e5e9f1;
    }

    .amount,
    .pickup-date {
        color: #979ea6;
        display: inline-block;
        font-size: 12px;
        margin-left: 5px;
    }

    .pickup-date {
        font-size: 13px;
    }

    .cost-adjustment-controls {
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;
    }
    .cost-adjustment-button {
        font-size: 16px;
        font-weight: 500;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.02);
        svg {
            margin-right: 8px;
        }
    }

    .value.unconfirmed {
        text-decoration: line-through;
    }

    .upload-order {
        margin-top: 16px;
        p {
            font-size: 14px;
            font-family: "Avenir Next";
            font-weight: 400;
            text-align: left;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .warehouse-order-item {
        &.wrapper {
            padding-top: 16px;
            padding-bottom: 63px;
            padding-left: 8px;
            padding-right: 8px;
        }

        &__wrapper.wrap {
            max-width: 100%;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        &__controls {
            .fixed-element {
                width: 100%;
                background-color: $white;
                padding: 12px 24px;
                border-top: 1px solid rgba(0, 0, 0, 0.07);
                box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.05);

                .btns {
                    margin-top: 0;
                }
            }
        }

        .section {
            padding-top: 30px;

            &:first-child {
                padding-top: 0;
            }
        }

        .header {
            .btns {
                .actions {
                    display: none;
                }

                .header-dropdown {
                    margin: 0;
                }
            }
        }

        .form-change-status {
            width: 100% !important;
            max-width: none;
            flex-direction: column;
            align-items: flex-start;

            .main-line {
                flex-direction: column;
                align-items: flex-start;

                .tip {
                    padding-left: 0;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

                .select {
                    width: 100%;
                }
            }

            .btns {
                margin-left: 0;

                button {
                    margin-right: 10px;
                }
            }
        }

        .group {
            padding-top: 28px;

            .item {
                flex-direction: column;

                .label {
                    width: auto;
                    padding-bottom: 5px;
                }

                .value {
                    width: auto;
                    max-width: 100%;
                }
            }
        }

        .status {
            align-items: flex-start;
        }

        .services {
            .services-info {
                flex-grow: 1;
            }
        }

        .rating {
            flex-direction: column;

            .groups {
                width: 100%;
            }

            .rating-item {
                width: 50%;
                margin-top: 10px;
            }

            .rating-comment {
                padding-top: 15px;

                .label {
                    color: $dark;
                }

                .value {
                    color: $grey;
                    padding-top: 5px;
                    font-style: italic;
                }
            }
        }

        .service-list {
            .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                .info-wrap {
                    display: flex;
                    align-items: center;
                }

                .value {
                    flex-grow: 0;
                }
            }
        }

        .photos-wrapper {
            margin-top: 8px;
        }

        .prices {
            align-items: flex-start;

            .item {
                flex-direction: row;
                &.total {
                    margin-right: 0;
                }
            }
        }
    }
}

@import "styles/global.scss";
.login-form > * {
    margin-bottom: 16px;
}

.login-form {
    h2 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: -.3px;
        color: #416283;
        padding-bottom: 0px;
        text-align: center;
    }
    .login-err {
        font-size: 12px;
        color:$error;
    }
}

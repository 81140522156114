@import 'styles/global.scss';

.order-cost-adjustment {
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row !important;

    &--carrier {
        .order-cost-adjustment__wrapper {
            width: calc(50% - 16px);

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 32px;
            }
        }
    }

    &--warehouse {
        width: 100%;
        height: auto;
        background-color: transparent;
        text-align: left;

        .order-cost-adjustment__wrapper {
            width: 100%;

            &:not(:first-child) {
                margin-top: 32px;
            }
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__label,
    &__value {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $black;
    }

    &__label,
    &__comment {
        padding-left: 32px;
    }

    &__label {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        .order-cost-adjustment__icon {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__comment {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    &__files {
        margin-left: auto;

        .label {
            width: auto !important;
        }

        .dropdown-documents {
            .download {
                height: auto;
            }

            .label {
                margin-left: 0;
            }
        }
    }

    &__btns-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        button {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            width: 144px;
            height: 48px;
            margin-left: 8px;
        }
    }

    &__confirm-text,
    &__reject-reason {
        margin-top: 16px;
    }

    &__confirm-text {
        margin-top: 16px;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
    }

    &__reject-reason {
        order: 10;

        .order-cost-adjustment__label {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
            padding: 0;
        }

        .order-cost-adjustment__value {
            margin-top: 2px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $black;
        }
    }

    // Adjustment Icons

    .icon-doc {
        color: $blue;
    }

    .icon-close {
        color: #ef8473;
        font-weight: bold;
        margin-right: 5px;
    }

    .icon-mail {
        color: #519cec;
        margin-right: 5px;
    }

    .icon-checkbox {
        color: #57e08c;
        margin-right: 5px;
    }

    &__status-icon {
        margin-left: 8px;
    }

    &__value.unconfirmed {
        text-decoration: line-through;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .order-cost-adjustment {
        display: block;

        &--carrier,
        &--warehouse {
            .order-cost-adjustment__wrapper {
                width: 100%;

                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 16px;
                }
            }
        }

        &__inner {
            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        &__reject-reason {
            order: initial;
        }
    }
}

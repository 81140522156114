.new-modal-warehouse {
    .btns {
        display: flex;
        justify-content: flex-end;

        button {
            width: 120px;
            margin-left: 20px;
        }
    }
}

@import '@/assets/scss/global.scss';

.warehouse-rating {
    .section {
        margin-top: 50px;

        &:first-child {
            margin-top: 0;
        }
    }

    .ratings {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            position: relative;

            .rating-item {
                text-transform: uppercase;
                font-size: 11px;
                font-weight: 500;
            }
        }

        .separator {
            width: 1px;
            height: 35px;
            background: #e2e7ed;
        }
    }

    .reviews {
        .review {
            display: flex;
            padding-bottom: 25px;
            border-bottom: 1px solid $border-color;
            margin-bottom: 25px;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }

            &-info {
                width: calc(50% - 20px);
                flex-shrink: 0;

                .header-info {
                    display: flex;
                    align-items: center;
                    padding-bottom: 10px;

                    .name {
                        max-width: 75%;
                        font-size: 13px;
                        font-weight: 600;
                        word-wrap: break-word;
                    }

                    .date {
                        font-size: 13px;
                        font-weight: 500;
                        color: $grey;
                        margin-left: 15px;
                    }
                }

                .comment {
                    font-size: 15px;
                    font-style: italic;
                    color: $dark;
                    word-break: break-word;
                }
            }

            &-rating {
                display: flex;
                justify-content: space-between;
                width: calc(50% - 20px);
                flex-shrink: 0;
                margin-left: 40px;
                padding-top: 26px;

                .rating-item {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .warehouse-rating {
        .section {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        .ratings {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            .item {
                position: relative;
                margin-bottom: 20px;

                &.average {
                    margin-bottom: 0;
                }
            }

            .separator {
                width: 100%;
                flex-grow: 1;
                height: 1px;
                background: #e2e7ed;
                margin: 15px 0;
            }
        }

        .reviews {
            .review {
                display: block;
                padding-bottom: 25px;
                margin-bottom: 25px;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                &-info {
                    width: auto;

                    .header-info {
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;

                        .name {
                            font-size: 13px;
                            font-weight: 600;
                        }

                        .date {
                            font-size: 13px;
                            font-weight: 500;
                            color: $grey;
                            margin-left: 15px;
                        }
                    }

                    .comment {
                        padding-bottom: 15px;
                    }
                }

                &-rating {
                    display: flex;
                    justify-content: space-between;
                    width: auto;
                    flex-shrink: 0;
                    margin-left: 0;
                    padding-top: 0;
                    flex-wrap: wrap;

                    .item {
                        width: 50%;
                        margin-bottom: 10px;
                    }

                    .rating-item {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@import 'styles/global.scss';

.modal-select-location {
    .map-wrapper {
        position: relative;
        width: 100%;
        height: 360px;

        .map {
            width: calc(100% + 60px);
            height: 100%;
            margin-left: -30px;
        }

        .marker-center {
            position: absolute;
            top: 50%;
            left: 50%;

            .loader .spinner {
                margin-left: -16px;
                margin-top: -16px;
                width: 32px;
                height: 32px;
            }

            .icon-pin {
                position: absolute;
                top: -12px;
                left: -9px;
                font-size: 25px;
                color: #3e3e3e;
            }

            .icon-round {
                position: absolute;
                top: -9px;
                left: -9px;
                width: 15px;
                height: 15px;
                background-color: $blue;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                border-radius: 50%;
            }
        }
    }

    .form-search-address {
        display: flex;
        margin-bottom: 1rem;

        .field {
            flex-grow: 1;
        }

        button {
            line-height: 38px;
            height: 40px;
            margin-left: 20px;
            min-width: 100px;
        }
    }

    .btns {
        padding-top: 30px;

        button {
            padding: 0 35px;
        }
    }
}
@media screen and (max-width: $media-limit-mobile) {
    .modal-select-location {
        .map-wrapper {
            height: 260px;

            .map {
                width: calc(100% + 30px);
                margin-left: -15px;
            }
        }

        .form-search-address {
            button {
                margin-left: 0;
                margin-bottom: 20px;
                min-width: auto;
            }
        }

        .btns {
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;

            button {
                padding: 0 35px;
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.photos-form {
  &__wrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: max-content max-content max-content;
  }

  &__item {
    position: relative;
    width: 144px;
    height: 107px;

    @media (max-width: $media-limit-mobile) {
      width: 249px;
      height: 185px;
    }

    > * {
      width: 100%;
      height: 100%;
    }
  }

  &__caption {
    font-size: 12px;
    line-height: 16px;
  }

  &__upload-btn {
    position: relative;
    width: 100%;
    background: #F7F8FA;
    border: 1px solid #519CEC;
    border-radius: 2px;
    margin-top: 17px;
    font-weight: bold;

    &:disabled {
      border-color: #F7F8FA;
    }
  }
}

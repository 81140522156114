@import 'styles/global.scss';

.form-filters {
    form {
        display: flex;
        align-items: flex-end;

        & > * {
            margin-left: 25px;
            display: flex;
            align-items: center;

            label {
                font-size: 11px;
                font-weight: 500;
                letter-spacing: -0.1px;
                color: #5c6064;
                text-transform: uppercase;
                padding-right: 10px;
            }
        }

        .input-text .input-wrapper {
            margin-bottom: 0;

            .control input {
                background: white;
            }
        }

        .field-id {
            position: relative;

            .submit {
                position: absolute;
                top: 1px;
                right: 2px;
                font-size: 12px;
                width: 40px;
                height: calc(100% - 2px);
                color: $dark-blue;
                background-color: #fff;
                border-radius: 3px;

                &:hover {
                    color: #557ca2;
                }

                &:active {
                    color: #254566;
                }
            }
        }

        .status {
            width: 250px;
        }

        .search {
            width: 182px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .form-filters {
        form {
            flex-direction: column;
            align-items: stretch;

            & > * {
                margin-left: 0;
                margin-bottom: 15px;
                flex-direction: column;
                align-items: stretch;

                label {
                    padding-right: 0;
                    padding-bottom: 5px;
                }
            }

            .field-id {
                position: relative;

                .submit {
                    top: auto;
                    bottom: 1px;
                    width: 40px;
                    height: 38px;
                    border-radius: 3px;
                }
            }

            .status {
                width: 100%;
            }

            .search {
                width: 100%;
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.reject-cost-adjustment {
    min-width: 300px;

    &__adjustment {
        display: block !important;
        border: none !important;
        padding: 0 !important;

        > div {
            width: 100% !important;
            margin-left: 0 !important;

            &:last-child {
                margin-top: 36px;
            }
        }
    }

    &__field:not(:last-child) {
        margin-bottom: 22px;
    }

    &__btns {
        display: flex;
        justify-content: space-between;

        button {
            flex-grow: 1;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    .dropdown-documents {
        .list {
            max-width: 250px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .reject-cost-adjustment {
        min-width: 260px;

        &__field {
            width: 100%;
            padding-right: 0;
        }
    }
}

@import '@/assets/scss/global.scss';

.wh-service {
    position: relative;
    margin-bottom: 16px;

    hr {
        margin: 15px 0;
        border: 0;
        border-top: 1px solid #e5e9f1;
    }

    //.wh-service-checkbox-title .label {
    //  font-weight: 500;
    //}

    .radio {
        color: $dark;

        .title {
            font-size: 11px;
            font-weight: 500;
            letter-spacing: -0.1px;
            color: $dark;
            text-transform: uppercase;
            padding-bottom: 12px;
            text-align: left;
        }

        .list {
            display: flex;

            .item {
                display: flex;
                align-items: center;
                cursor: pointer;

                .control {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    background-color: #f7f8fa;
                    border: solid 1px #e2e7ed;
                    border-radius: 50%;

                    &:before {
                        display: none;
                        width: 6px;
                        height: 6px;
                        content: '';
                        border-radius: 50%;
                        background-color: $blue;
                    }
                }

                &.active {
                    .control {
                        border: solid 1px $blue;
                        border-radius: 50%;

                        &:before {
                            display: block;
                        }
                    }
                }

                .service-label {
                    font-size: 15px;
                    margin-left: 10px;
                    white-space: nowrap;
                }
            }
        }

        &.wrap {
            .list {
                .item {
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &.column {
            .list {
                flex-direction: column;

                .item {
                    margin-top: 15px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .btn-upload {
        input {
            display: none;
            width: 100%;
            height: 100%;
            font-size: 100px;
            opacity: 0;
            cursor: pointer;
        }
    }

    .upload-wrap {
        display: flex;
        align-items: center;

        .service-label {
            font-size: 13px;
        }

        .list {
            margin-left: 5px;

            .item {
                line-height: 40px;
                font-size: 14px;
            }
        }
    }

    .wh-service-title-wrapper {
        display: flex;
    }

    .wh-service-title {
        margin-bottom: 16px;
    }

    .wh-service-notes {
        display: flex;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 16px;
    }

    .wh-service-checkbox-title {
        font-size: 16px;
        margin-bottom: 16px;
        color: #5c6064;
    }

    .service-price-type span {
        line-height: 23px;
        border-radius: 2px;
        background-color: #f3f6fc;
        font-size: 12px;
        letter-spacing: normal;
        color: #979ea6;
        padding: 0 7px;
        margin-left: 10px;
    }

    .w-error {
        font-size: 11px;
        color: #ed6868;
        min-height: 25px;
        padding: 5px 0;
    }

    .service-document-info .service-label {
        color: #000;
        display: inline-flex;
        font-size: 16px;
        font-weight: 600;
        margin-right: 10px;
    }

    .service-document-expiration-info {
        margin: 8px 0;
    }

    .service-document-status {
        display: inline-flex;
        margin-top: 16px;
    }

    .service-status {
        margin-left: 10px;
    }

    .document-status,
    .status {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.has-style {
            border-radius: 5px;
            padding: 5px 7px;
            line-height: 16px;
            letter-spacing: -0.1px;

            &.pending {
                background-color: #fbf4eb;
                color: #e09c3c;
            }

            &.success {
                background-color: #e9f8ef;
                color: #52a775;
            }

            &.failed {
                background-color: #fbebeb;
                color: #df5151;
            }

            &.common {
                background-color: #f4f4f4;
            }
        }
    }

    .suggestion-prices {
        .suggestion-prices-title {
            margin: 4px 0;

            span {
                font-size: 12px;
            }
        }
    }

    .service-document-control {
        display: flex;
        padding: 16px 0;

        & > * {
            margin-right: 8px;
        }

        & > button {
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            min-width: 70px;
        }

    }

    .service-rates {
        display: flex;
        margin-bottom: 8px;

        .service-rate-item {
            display: flex;
            flex-direction: column;
            width: 150px;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .rates {
        display: flex;

        .rate {
            position: relative;
            flex-direction: column;
            align-items: center;
            align-content: center;
            text-align: center;
            border-bottom: none;
            font-size: 12px;
            color: $border-color;

            &.active {
                color: $main;
                width: 150px;
                margin-right: 16px;
                text-align: left;
            }

            .period {
                position: absolute;
                top: -16px;
                font-size: 10px;
                white-space: nowrap;
                color: $grey;
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
                text-align: center;
            }

            .value {
                width: 100%;
                font-weight: 500;
            }
        }
    }

    &.highlighted {
        .wh-service-title {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

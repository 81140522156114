@import '@/assets/scss/global.scss';

.carrier-search {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(60deg, #78b9ff, #56a0ef 86%, #519cec);

    & > div {
        position: relative;
        padding: 50px 0 0 0;

        .search-wrap {
            flex-grow: 1;
            padding-bottom: 100px;

            .search-header {
                color: #fff;
                text-align: center;

                .icon-search {
                    font-size: 60px;
                }

                .title {
                    padding: 20px 0 10px 0;
                    font-family: 'UniNeue';
                    font-size: 36px;
                    font-weight: bold;
                    letter-spacing: -0.3px;
                }

                .description {
                    font-size: 15px;
                    padding-bottom: 40px;
                }
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .carrier-search {
        padding: 0 10px;

        & > div {
            position: relative;
            padding: 30px 0 0 0;

            .search-wrap {
                flex-grow: 1;
                padding-bottom: 20px;

                .search-header {
                    text-align: left;
                    position: relative;
                    padding-left: 60px;

                    .icon-search {
                        font-size: 50px;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                    }

                    .title {
                        padding: 0 0 5px 0;
                        font-size: 28px;
                        font-weight: bold;
                        letter-spacing: -0.3px;
                    }

                    .description {
                        font-size: 13px;
                        padding-bottom: 25px;
                    }
                }
            }
        }
    }
}

@import 'styles/global.scss';

.editable-document-view {
    position: relative;
    background-color: $light-silver;
    width: 100%;
    padding: 12px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-top-left-radius: 0;
    display: flex;
    align-items: stretch;
    min-height: 50px;

    &.disabled {
        pointer-events: none;
        background-color: $silver;

        .editable-document-view__link {
            color: $grey;
        }
    }

    &__link,
    &__remove {
        margin: -12px 0;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
            background-color: #fcfcfc;
        }
    }

    &__link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        color: $black;
        font-weight: 500;
        padding-left: 15px !important;
        font-size: 14px;

        white-space: nowrap;

        svg,
        .loader {
            width: 24px;
            margin-right: 8px;
        }
    }

    &.customer {
        margin-top: 24px;
        padding: 6px 20px;
    }

    &__number {
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: none;
        background: white;
        cursor: default;
        color: black;
        font-weight: 500;
        padding: 1px 8px;
        border-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        top: -22px;
        left: -1px;
        text-align: center;
        font-family: 'UniNeue', sans-serif;
        font-size: 14px;
    }

    &__remove {
        width: 44px;
        padding: 0;
        margin-left: 12px;
    }

    .loader {
        position: static;
    }
}

@import 'styles/global.scss';
.datepicker {
    .label {
        font-family: AvenirNext;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.1px;
        color: $dark;
        text-transform: uppercase;
        padding-bottom: 7px;
        text-align: left;
        white-space: nowrap;
    }

    .result-picker {
        position: relative;
        min-width: 225px;
        height: 40px;
        background-color: #f7f8fa;
        border: solid 1px $border-color;
        font-size: 15px;
        color: $dark;
        line-height: 38px;
        padding-left: 13px;
        user-select: none;
        cursor: pointer;

        &.has-error {
            border-color: $error;
        }

        &.white {
            background-color: #fff;
        }

        .icon-calendar {
            position: absolute;
            top: 0;
            right: 12px;
            font-size: 12px;
            color: $dark-blue;
            display: flex;
            align-items: center;
            height: 100%;

            &:hover {
                color: #557ca2;
            }

            &:active {
                color: #254566;
            }
        }
    }

    .date-picker {
        position: absolute;
        top: 7px;
        right: 0;
        width: 260px;
        padding: 13px 15px 15px 15px;
        color: $dark;
        background: $white;
        box-shadow: 0 1px 3px 0 rgba($black, 0.05);
        border: 1px solid $border-color;
        font-size: 12px;
        border-radius: 4px;
        user-select: none;

        &:before {
            position: absolute;
            top: -4px;
            right: 15px;
            width: 6px;
            height: 6px;
            content: '';
            border-left: 1px solid $border-color;
            border-top: 1px solid $border-color;
            transform: rotate(45deg);
            background-color: $white;
        }

        .datepicker-header {
            .month-header {
                display: flex;
                align-items: center;

                .current-month {
                    flex-grow: 1;
                    text-align: center;
                    font-weight: 500;
                    text-transform: capitalize;
                    font-size: 14px;
                    font-family: 'UniNeue';
                    color: $dark;
                }
            }

            .days-header {
                display: flex;
                padding: 14px 0 9px;

                & > * {
                    text-align: center;
                    flex-grow: 1;
                    flex-shrink: 0;
                    flex-basis: 0;
                    overflow: hidden;
                    color: #9caec5;
                    font-size: 12px;
                }
            }
        }

        .arrow {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            cursor: pointer;
            border: 1px solid $border-color;
            border-radius: 3px;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 5px;
                height: 5px;
                margin: auto;
                content: '';
                border-top: 1px solid $dark;
                border-left: 1px solid $dark;
            }

            &:not(.double) {
                &:after {
                    display: none;
                }
            }

            &.arrow-left:before,
            &.arrow-left:after {
                left: 2px;
                transform: rotate(-45deg);
            }

            &.arrow-right:before,
            &.arrow-right:after {
                right: 2px;
                transform: rotate(135deg);
            }

            &.double {
                &.arrow-right:after {
                    right: -2px;
                }

                &.arrow-right:before {
                    right: 8px;
                }

                &.arrow-left:after {
                    left: -2px;
                }

                &.arrow-left:before {
                    left: 7px;
                }

                &.arrow-left {
                    margin-right: 5px;
                }

                &.arrow-right {
                    margin-left: 5px;
                }
            }
        }

        .body {
            font-size: 10px;
            font-weight: 400;

            .row-body {
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }

                .col:not(.disabled) {
                    cursor: pointer;
                }
            }

            .row {
                display: flex;

                &.row-header {
                    .col {
                        font-weight: 600;
                        line-height: 1.13;
                        font-size: 14px;
                    }
                }

                .col {
                    position: relative;
                    z-index: 1;
                    padding: 2px;
                    flex-grow: 1;
                    flex-shrink: 0;
                    flex-basis: 0;
                    text-align: center;
                    border-radius: 2px;

                    .date {
                        margin: 0 auto;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                    }

                    &.selected:not(.limit-date) {
                        background: #e9ecf2;
                        border-radius: 0;
                    }

                    &.disabled {
                        pointer-events: none;
                        color: rgba(81, 81, 95, 0.4);
                    }

                    &.other-month {
                        color: rgba(81, 81, 95, 0.4);
                        opacity: 0;
                    }

                    &.limit-date {
                        position: relative;
                        z-index: 2;
                        color: $white;
                        border-radius: 0;

                        .active {
                            position: absolute;
                            top: -2px;
                            left: 0;
                            right: 0;
                            z-index: -1;
                            width: 23px;
                            height: 23px;
                            margin: auto;
                            border-radius: 3px;
                            background: $blue;
                        }
                    }

                    &.start-date {
                        box-shadow: inset 5px 0 $white;

                        & + .selected:not(.limit-date) {
                            box-shadow: -5px 0 #e9ecf2;
                        }

                        & + .end-date {
                            background: $white;
                        }

                        .active:after {
                            position: absolute;
                            top: 1px;
                            right: -2px;
                            z-index: -1;
                            width: 7px;
                            height: 13px;
                            margin: auto;
                            content: '';
                            border-radius: 2px;
                            background: $blue;
                            transform: rotate(-23deg);
                        }

                        .active:before {
                            position: absolute;
                            right: -2px;
                            bottom: 1px;
                            z-index: -1;
                            width: 7px;
                            height: 13px;
                            margin: auto;
                            content: '';
                            border-radius: 2px;
                            background: $blue;
                            transform: rotate(23deg);
                        }
                    }

                    &.end-date {
                        box-shadow: inset -5px 0 $white;
                        background: #e9ecf2;

                        &.start-date {
                            background: transparent;
                        }

                        .active:after {
                            position: absolute;
                            top: 1px;
                            left: -2px;
                            z-index: -1;
                            width: 7px;
                            height: 13px;
                            margin: auto;
                            content: '';
                            border-radius: 2px;
                            background: $blue;
                            transform: rotate(23deg);
                        }

                        .active:before {
                            position: absolute;
                            left: -2px;
                            bottom: 1px;
                            z-index: -1;
                            width: 7px;
                            height: 13px;
                            margin: auto;
                            content: '';
                            border-radius: 2px;
                            background: $blue;
                            transform: rotate(-23deg);
                        }
                    }
                }
            }
        }

        .datepicker-footer {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;

            button {
                width: 100%;
            }

            &.has-reset {
                button {
                    width: calc(50% - 5px);
                }
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.warehouses-list {
    position: relative;
    display: flex;
    justify-content: center;

    & > div {
        padding-bottom: 50px;
    }

    .form-search {
        box-shadow: none;
        margin-bottom: 25px;
    }

    .result {
        border-radius: 4px;
        background-color: #fff;
        padding: 30px;
        text-align: center;
        font-weight: 500;

        .link {
            color: $blue;
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .warehouses-list {
        & > div {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;

            .page-header {
                display: flex;
                justify-content: space-between;

                .icon-arrow {
                    display: flex;
                    align-items: center;
                    padding: 30px 15px 15px 15px;
                    color: $dark-blue;
                    font-size: 10px;
                    transition: none;

                    &:before {
                        transition: none;
                    }

                    &.opened:before {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .form-search {
            &.mobile-hidden {
                display: none;
            }
        }

        .map {
            height: auto;
            flex-grow: 1;
            min-height: 300px;
        }
    }
}

.company-managers {
    .control {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}

.company-managers-footer {
    display: flex;
    justify-content: flex-end;
}
@import 'styles/global.scss';

.fixed-element {
    display: contents;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
}

.carrier-booking-section {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 5px 20px 0 rgba(18, 51, 87, 0.08);

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'UniNeue', serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #486180;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

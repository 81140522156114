@import '@/assets/scss/global.scss';

.olimp-header {
    background-color: #fff;
    padding: 0 10px;

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        font-family: 'UniNeue';
        color: $dark-blue;
    }

    &__auth {
        display: flex;

        button:not(:last-child) {
            margin-right: 8px;
        }
    }

    &__nav {
        margin-left: 24px;
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .olimp-header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        border-bottom: 1px solid $border-color;

        &.dev {
            top: 41px;
            border-top: 1px solid $border-color;
        }

        &.wrapper {
            position: fixed;
        }

        &__inner {
            height: 65px;
        }

        &__burger {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 67px;
            height: 67px;

            &.opened {
                color: $blue;
            }
        }
    }
}

@import 'styles/global.scss';

.info-list {
    display: flex;
    flex-wrap: wrap;

    .description {
        padding-bottom: 30px;
        font-size: 14px;
    }

    .item {
        margin-bottom: 25px;

        .label {
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: -0.1px;
            color: #5c6064;
            text-transform: uppercase;
            padding-bottom: 5px;
        }

        .value {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.2px;
            color: $dark-old;
            display: flex;
            align-items: flex-end;

            .days-count {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: -0.1px;
                color: #979ea6;
                margin-left: 10px;
                margin-bottom: 3px;
            }
        }

        &.full {
            flex-grow: 1;
            width: 100%;
        }

        &.date {
            margin-left: 30px;
        }
    }

    .btns {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
            width: 120px;
            margin-left: 20px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .info-list {
        display: flex;
        flex-wrap: wrap;

        .item {
            margin-bottom: 15px;

            .value {
                font-size: 15px;

                .days-count {
                    margin-bottom: 3px;
                }
            }

            &.date {
                width: 100%;
                flex-grow: 1;
                margin-left: 0;
            }
        }
    }
}

@import '@/assets/scss/global.scss';

.warehouse-calendar {
    &.page {
        .wrap {
            padding: 0;

            .section {
                .header {
                    align-items: flex-start;
                    padding: 30px 20px;

                    .left {
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                    }

                    .month {
                        font-size: 28px;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }

    .header-days {
        display: flex;

        .cell {
            width: calc(100% / 7);
            padding: 10px 20px;
        }
    }

    .nav {
        display: flex;

        button {
            margin-left: 8px;
            height: 37px;

            &.total {
                padding: 0 25px;
            }

            &.arrow {
                width: 37px;
                padding: 0;
            }

            .icon {
                transition: none;
            }

            .prev.icon {
                transform: rotate(180deg);
            }
        }
    }

    .calendar {
        .row {
            display: flex;
            border-bottom: 1px solid $border-color;

            &:first-child {
                border-top: 1px solid $border-color;
            }

            .cell {
                width: calc(100% / 7);
                flex-shrink: 0;
                border-left: 1px solid $border-color;

                &:first-child {
                    border-left: 1px solid transparent;
                }

                .item {
                    padding: 15px 20px;
                    min-height: 110px;

                    &:not(.other-month).today {
                        background-color: $blue;
                        color: #fff;

                        .date {
                            color: #fff;
                        }

                        .info .params .param .icon {
                            color: #fff;
                        }
                    }

                    &:not(.has-info) {
                        pointer-events: none;
                    }

                    &.other-month {
                        opacity: 0.5;
                    }

                    .icon-status {
                        float: right;
                    }

                    &.has-info {
                        cursor: pointer;
                        transition: all 0.2s linear;

                        &:not(.today):hover {
                            background-color: #f1f4fa;
                        }
                    }

                    .date {
                        display: inline-block;
                        font-size: 26px;
                        font-weight: 600;
                        line-height: 1.15;
                        letter-spacing: -0.2px;
                        color: $dark-old;
                    }
                }
            }
        }
    }

    .info {
        font-size: 15px;
        padding-top: 7px;

        .params {
            display: flex;
            align-items: center;

            .param {
                margin-left: 15px;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-left: 0;
                }

                .icon-status.pending,
                .icon-status.sub-order {
                    margin-right: 7px;
                }

                .icon {
                    color: $grey-blue;
                    font-size: 14px;
                    margin-right: 7px;
                }
            }
        }

        .total {
            padding-top: 5px;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .warehouse-calendar {
        padding: 0;

        &.page {
            .page-title {
                padding: 30px 20px 15px 20px;
            }

            .wrap {
                .section {
                    .header {
                        position: relative;
                        padding: 20px 20px;

                        .month {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .nav {
            position: absolute;
            right: 20px;
            top: -50px;

            button {
                margin-left: 5px;
                height: 31px;
                line-height: 29px;

                &.total {
                    padding: 0 10px;
                    font-size: 13px;
                }

                &.arrow {
                    width: 31px;
                    font-size: 10px;
                }
            }
        }

        .header-days {
            .cell {
                padding: 5px;
                font-size: 13px;
            }
        }

        .calendar {
            .row {
                .cell {
                    .item {
                        padding: 5px;
                        min-height: 101px;

                        .date {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }
            }
        }

        .info:not(.summary) {
            font-size: 15px;
            padding-top: 7px;

            .params {
                display: block;

                .param {
                    margin-left: 0;
                    display: block;

                    & > * {
                        display: block;
                        font-size: 11px;
                    }

                    .icon {
                        font-size: 11px;
                        margin-right: 0;
                    }
                }
            }

            .total {
                padding-top: 1px;
                font-size: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

@import "styles/global.scss";


.distance-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  svg {
    fill: $grey;
    width: 18px;
    margin-right: 12px;

    linearGradient > stop:nth-child(1) {
      stop-color: silver;
    }
    linearGradient > stop:nth-child(2) {
      stop-color: $grey;
    }
    linearGradient > stop:nth-child(3) {
      stop-color: $grey;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
  }

  .route-time {
    color: $grey;
  }
}
